import React, { useState, useEffect, useContext } from 'react';
import { Card, CardContent, Skeleton } from '@mui/material';
import { TrendingUp } from '@mui/icons-material';
import MetricCard from './MetricCard';
import ApiService from '../../services/api';
import { useAppSettings } from '../../contexts/AppSettingsContext';
import ErrorMessage from '../ErrorMessage';
import { useSession } from '../../contexts/SessionContext';

const HighArticlesCard = () => {
  const [highData, setHighData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const api = ApiService();
  const {
    selectedPeriod,
    setSelectedPeriod, profile, defaultProfile
  }  = useAppSettings();  
  const lastLogin = localStorage.getItem('lastLogin');
  const { session } = useSession();

  
  useEffect(() => {
    const fetchHighData = async () => {
      try {
        setLoading(true);
        const params = isNaN(selectedPeriod) 
          ? { last_login: lastLogin } 
          : { days: selectedPeriod };

          if (session.userProfile.defaultProfile !== undefined && session.userProfile.defaultProfile > 0) {
            params.profile = session.userProfile.defaultProfile;
          }

          if (isNaN(selectedPeriod)) {
            // Si period n'est pas un nombre, on suppose que c'est une date ISO
            params.last_login = lastLogin;
          } else {
            // Sinon, c'est un nombre de jours
            params.days = selectedPeriod;
          }

        const response = await api.get('/stats/high', params );
        setHighData(response.data);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchHighData();
  }, [selectedPeriod, lastLogin, session.userProfile.defaultProfile]);

  if (loading) {
    return (
      <Card sx={{ minWidth: 400, maxWidth: 600, boxShadow: 3 }}>
        <CardContent>
          <Skeleton variant="text" width="60%" />
          <Skeleton variant="rectangular" height={150} />
          <Skeleton variant="text" width="80%" />
        </CardContent>
      </Card>
    );
  }
  if (error) {
    return <ErrorMessage message={error.message} />;
  }
  if (!highData) return null;

  return (
    <MetricCard
      title="HIGH ARTICLES"
      profile={session.userProfile.defaultProfile}
      value={highData.count}
      change={highData.change}
      comparisonText={isNaN(selectedPeriod) ? "Since last login" : `Last ${selectedPeriod} days`}
      icon={TrendingUp}
      iconColor="warning"
      currentMonthArticles={highData.current_month}
      previousMonthArticles={highData.previous_month}
    />
  );
};

export default HighArticlesCard;