import React from 'react';
import { Box, Typography, Button, Paper, Fade, useTheme, alpha } from '@mui/material';
import { Search as SearchIcon, RefreshCw, FilterX, BookmarkX, Inbox } from 'lucide-react';

const EmptyState = ({ message, onReset, filters }) => {
  const theme = useTheme();

  // Déterminer le mode d'affichage en fonction des filtres
  const mode = {
    isSearch: Boolean(filters?.searchTerm),
    isSaved: filters?.selectedView === 'saved',
    isFiltered: Boolean(
      filters?.timeRange !== '0' || 
      filters?.selectedProfile !== '0' || 
      filters?.sortOrder !== 'desc'
    )
  };

  const handleReset = () => {
    if (onReset) {
      // Si c'est une recherche, réinitialiser uniquement la recherche
      if (filters?.searchTerm) {
        onReset('search');
      } else {
        // Sinon réinitialiser tous les filtres
        onReset('all');
      }
    }
  };

  // Sélectionner l'icône et les messages appropriés
  const config = {
    icon: mode.isSearch ? SearchIcon :
          mode.isSaved ? BookmarkX :
          mode.isFiltered ? FilterX :
          Inbox,
    title: mode.isSearch ? "No search results" :
           mode.isSaved ? "No saved articles" :
           mode.isFiltered ? "No matching articles" :
           "No articles yet",
    buttonText: mode.isSearch ? "Clear search" :
                mode.isFiltered ? "Reset filters" :
                "Refresh",
    buttonIcon: mode.isSearch || mode.isFiltered ? FilterX : RefreshCw
  };

  return (
    <Fade in={true} timeout={800}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          minHeight: '60vh',
          px: 3,
        }}
      >
        <Paper
          elevation={0}
          sx={{
            position: 'relative',
            overflow: 'hidden',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            padding: 6,
            width: '100%',
            maxWidth: 480,
            borderRadius: 4,
            border: `1px solid ${theme.palette.divider}`,
            background: `linear-gradient(145deg, 
              ${alpha(theme.palette.background.paper, 0.9)} 0%, 
              ${alpha(theme.palette.background.paper, 0.7)} 100%
            )`,
            backdropFilter: 'blur(8px)',
          }}
        >
          {/* Éléments décoratifs */}
          <Box
            sx={{
              position: 'absolute',
              top: -100,
              left: -100,
              width: 200,
              height: 200,
              borderRadius: '50%',
              background: `radial-gradient(circle, 
                ${alpha(theme.palette.primary.main, 0.08)} 0%, 
                transparent 70%
              )`,
            }}
          />
          <Box
            sx={{
              position: 'absolute',
              bottom: -60,
              right: -60,
              width: 120,
              height: 120,
              borderRadius: '50%',
              background: `radial-gradient(circle, 
                ${alpha(theme.palette.primary.main, 0.05)} 0%, 
                transparent 70%
              )`,
            }}
          />

          {/* Icône principale */}
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: 96,
              height: 96,
              borderRadius: '50%',
              backgroundColor: alpha(theme.palette.primary.main, 0.1),
              mb: 3,
              transform: 'translateY(-8px)',
            }}
          >
            <config.icon
              size={48}
              style={{ 
                color: theme.palette.primary.main,
                opacity: 0.8,
              }}
            />
          </Box>

          {/* Textes */}
          <Typography
            variant="h5"
            sx={{
              fontWeight: 600,
              textAlign: 'center',
              mb: 1,
              color: theme.palette.text.primary,
            }}
          >
            {config.title}
          </Typography>

          <Typography
            variant="body1"
            sx={{
              textAlign: 'center',
              mb: 4,
              color: theme.palette.text.secondary,
              maxWidth: 320,
            }}
          >
            {message}
          </Typography>

          {/* Bouton d'action */}
          {onReset && (
            <Button
              variant="contained"
              color="primary"
              onClick={handleReset}
              startIcon={<config.buttonIcon size={18} />}
              sx={{
                borderRadius: 2,
                px: 3,
                py: 1,
                textTransform: 'none',
                fontSize: '1rem',
                boxShadow: theme.shadows[2],
                '&:hover': {
                  boxShadow: theme.shadows[4],
                },
              }}
            >
              {config.buttonText}
            </Button>
          )}
        </Paper>
      </Box>
    </Fade>
  );
};

export default EmptyState;