import React, { useState } from 'react';
import {
  Box,
  Tabs,
  Tab,
  Typography,
  Paper,
  Card,
  CardContent,
  Grid,
  useTheme,
  alpha
} from '@mui/material';
import {
  Users,
  MessageSquare,
  Newspaper,
  Settings,
  ShieldAlert,
  Activity,
  Database
} from 'lucide-react';
import CreateMessageForm from './CreateMessageForm';
import UserManagement from './UserManagement';
import MessagesList from './MessagesList';


// Section headers component
const SectionHeader = ({ icon: Icon, title, subtitle }) => {
  const theme = useTheme();
  return (
    <Box sx={{ mb: 4 }}>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mb: 1 }}>
        <Icon size={24} color={theme.palette.primary.main} />
        <Typography variant="h5" fontWeight="bold">
          {title}
        </Typography>
      </Box>
      {subtitle && (
        <Typography variant="body2" color="text.secondary">
          {subtitle}
        </Typography>
      )}
    </Box>
  );
};

// Analytics card component
const AnalyticsCard = ({ title, value, icon: Icon, trend }) => {
  const theme = useTheme();
  return (
    <Card 
      elevation={0}
      sx={{ 
        border: `1px solid ${theme.palette.divider}`,
        borderRadius: 2,
        height: '100%'
      }}
    >
      <CardContent>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
          <Box>
            <Typography color="text.secondary" gutterBottom variant="body2">
              {title}
            </Typography>
            <Typography variant="h4" fontWeight="bold">
              {value}
            </Typography>
          </Box>
          <Box 
            sx={{ 
              p: 1,
              borderRadius: 1,
              bgcolor: alpha(theme.palette.primary.main, 0.1)
            }}
          >
            <Icon size={20} color={theme.palette.primary.main} />
          </Box>
        </Box>
        {trend && (
          <Typography 
            variant="body2" 
            color={trend > 0 ? 'success.main' : 'error.main'}
            sx={{ mt: 1 }}
          >
            {trend > 0 ? '+' : ''}{trend}% vs last month
          </Typography>
        )}
      </CardContent>
    </Card>
  );
};

// Main admin dashboard component
const AdminDashboard = () => {
  const [currentTab, setCurrentTab] = useState(0);
  const theme = useTheme();

  const tabs = [
    { label: 'Overview', icon: Activity },
    { label: 'User Management', icon: Users },
    { label: 'Content Management', icon: Newspaper },
    { label: 'Messages', icon: MessageSquare },
    { label: 'Security', icon: ShieldAlert },
    { label: 'System', icon: Database },
    { label: 'Settings', icon: Settings }
  ];

  return (
    <Box sx={{ p: 3 }}>
      <Paper 
        elevation={0}
        sx={{ 
          borderRadius: 3,
          border: `1px solid ${theme.palette.divider}`,
          overflow: 'hidden'
        }}
      >
        {/* Tabs Header */}
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs 
            value={currentTab} 
            onChange={(e, newValue) => setCurrentTab(newValue)}
            variant="scrollable"
            scrollButtons="auto"
          >
            {tabs.map((tab, index) => (
              <Tab
                key={index}
                icon={<tab.icon size={18} />}
                iconPosition="start"
                label={tab.label}
                sx={{ 
                  minHeight: 48,
                  textTransform: 'none',
                  fontSize: '0.9rem'
                }}
              />
            ))}
          </Tabs>
        </Box>

        {/* Tab Content */}
        <Box sx={{ p: 3 }}>
          {/* Overview Tab */}
          {currentTab === 0 && (
            <Box>
              <SectionHeader
                icon={Activity}
                title="Dashboard Overview"
                subtitle="Monitor key metrics and system health"
              />
              
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6} md={3}>
                  <AnalyticsCard
                    title="Total Users"
                    value="1,234"
                    icon={Users}
                    trend={12}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <AnalyticsCard
                    title="Active Subscriptions"
                    value="856"
                    icon={ShieldAlert}
                    trend={8}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <AnalyticsCard
                    title="Articles Today"
                    value="142"
                    icon={Newspaper}
                    trend={-5}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <AnalyticsCard
                    title="System Messages"
                    value="24"
                    icon={MessageSquare}
                    trend={15}
                  />
                </Grid>
              </Grid>
            </Box>
          )}
          {/* 'Users Management' */}
          {currentTab === 1 && (
            <Box>
              <SectionHeader
                icon={Users}
                title="Users Management"
                subtitle="Manage users and roles"
              />
              <UserManagement />
            </Box>
          )}
          {/* 'Messages Management' */}
          {currentTab === 3 && (
            <Box>
              <SectionHeader
                icon={MessageSquare}
                title="Messages Management"
                subtitle="Manage messages and notifications"
              />
              <MessagesList />
            </Box>
          )}
          {/* Content Management Tab */}
          {/* Autres onglets à implémenter */}
          
        </Box>
      </Paper>
    </Box>
  );
};

export default AdminDashboard;