// pages/UnauthorizedPage.jsx
import React from 'react';
import { Box, Typography, Button } from '@mui/material';
import { Shield, Home } from 'lucide-react';
import { Link } from 'react-router-dom';

const UnauthorizedPage = () => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      minHeight="100vh"
      gap={3}
    >
      <Shield size={64} color="error" />
      <Typography variant="h4" component="h1">
        Access Denied
      </Typography>
      <Typography variant="body1" color="text.secondary" align="center">
        You don't have permission to access this page.
      </Typography>
      <Button
        component={Link}
        to="/"
        variant="contained"
        startIcon={<Home size={16} />}
      >
        Return to Home
      </Button>
    </Box>
  );
};

export default UnauthorizedPage;