
import SecurityIcon from '@mui/icons-material/Security';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import GppBadIcon from '@mui/icons-material/GppBad';
import PersonIcon from '@mui/icons-material/Person';

const profileIcons = {
    'Cybersecurity Expert': SecurityIcon,
    'Security Analyst': AccountBalanceIcon,
    'CISO': PersonIcon,
    'Incident Response Manager': GppBadIcon,
    // Vous pouvez ajouter d'autres profils et icônes selon vos besoins
  };

  export default profileIcons;