// App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import CssBaseline from '@mui/material/CssBaseline';
import { SessionProvider } from './contexts/SessionContext';
import { AppSettingsProvider } from './contexts/AppSettingsContext';
import { ThemeProvider } from './contexts/ThemeContext'; // Utilisation du ThemeProvider personnalisé
import Layout from './pages/Layout';
import AuthProvider from './components/auth/AuthProvider';
import { SnackbarProvider } from './contexts/SnackbarContext';
// Pages de l'application
import Dashboard from './pages/Dashboard';
import Support from './pages/Support';
import Settings from './pages/Settings';
//import Saved from './pages/Saved';
import NewsContainer from './pages/NewsContainer';
import SubscriptionGuard from './components/subscription/SubscriptionGuard';
import SubscriptionSuccessPage from './components/subscription/SubscriptionSuccessPage';
import MessagesAdmin from './components/admin/MessagesAdmin';
import ProtectedRoute from './components/auth/ProtectedRoute';
import UnauthorizedPage from './pages/UnauthorizedPage';
import AdminDashboard from './components/admin/AdminDashboard';


const MainApp = () => {
  return (
    <SnackbarProvider>
      <Router>
        <CssBaseline /> {/* Assurez-vous que CssBaseline est appliqué ici */}
        <Layout>
          <Routes>
            <Route path="/dashboard" element={<Dashboard />} />
            {/* <Route path="/newsfeed" element={<Newsfeed />} /> */}
            <Route path="/newsfeed/*" element={<NewsContainer />} />
            <Route path="/support" element={<Support />} />
            <Route path="/settings" element={<Settings />} />
            {/* <Route path="/saved" element={<Saved />} /> */}
            <Route path="/" element={<Navigate to="/dashboard" replace />} />
            <Route path="/subscription/success" element={<SubscriptionSuccessPage />} />
            <Route path="/unauthorized" element={<UnauthorizedPage />} />

            {/* Route nécessitant le rôle 'admin' */}
            <Route path="/admin" element={
              <ProtectedRoute
                element={AdminDashboard}
                requiredRoles={['Admin']}
              />
            } />
          </Routes>
        </Layout>
      </Router>
    </SnackbarProvider>

  );
};

function App() {
  return (
    <AuthProvider>
      <SessionProvider>
        <AppSettingsProvider>
          <ThemeProvider>
            <MainApp />
          </ThemeProvider>
        </AppSettingsProvider>
      </SessionProvider>
    </AuthProvider>
  );
}

export default App;
