import React from 'react';
import { Box, Typography, Fade, Paper, Divider, useTheme, alpha } from '@mui/material';
import { Newspaper, FilterX, BookmarkX, Search } from 'lucide-react';

const EndOfArticles = ({ filters }) => {
  const theme = useTheme();
  
  // Déterminer le mode d'affichage en fonction des filtres
  const mode = {
    isSearch: Boolean(filters?.searchTerm),
    isSaved: filters?.selectedView === 'saved',
    isFiltered: Boolean(
      filters?.timeRange !== '0' || 
      filters?.selectedProfile !== '0' || 
      filters?.sortOrder !== 'desc'
    )
  };

  // Sélectionner l'icône appropriée
  const Icon = mode.isSearch ? Search : 
               mode.isSaved ? BookmarkX : 
               mode.isFiltered ? FilterX : 
               Newspaper;

  // Déterminer les messages en fonction du mode
  const messages = {
    title: mode.isSearch ? "End of Search Results" :
           mode.isSaved ? "End of Saved Articles" :
           mode.isFiltered ? "End of Filtered Results" :
           "You're All Caught Up!",
    
    subtitle: mode.isSearch ? "We've found all articles matching your search criteria." :
              mode.isSaved ? "You've reached the end of your saved articles." :
              mode.isFiltered ? "No more articles match your current filters." :
              "Check back later for new cybersecurity updates."
  };

  return (
    <Fade in={true} timeout={800}>
      <Paper
        elevation={0}
        sx={{
          position: 'relative',
          overflow: 'hidden',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          padding: 4,
          margin: 2,
          borderRadius: 3,
          border: `1px solid ${theme.palette.divider}`,
          background: `linear-gradient(45deg, 
            ${alpha(theme.palette.primary.main, 0.02)} 0%, 
            ${alpha(theme.palette.background.paper, 0.8)} 50%,
            ${alpha(theme.palette.primary.main, 0.02)} 100%
          )`,
          backdropFilter: 'blur(8px)',
        }}
      >
        {/* Cercle décoratif */}
        <Box
          sx={{
            position: 'absolute',
            top: -100,
            right: -100,
            width: 200,
            height: 200,
            borderRadius: '50%',
            background: `radial-gradient(circle, 
              ${alpha(theme.palette.primary.main, 0.1)} 0%, 
              ${alpha(theme.palette.primary.main, 0)} 70%
            )`,
          }}
        />

        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: 80,
            height: 80,
            borderRadius: '50%',
            backgroundColor: alpha(theme.palette.primary.main, 0.1),
            marginBottom: 3,
          }}
        >
          <Icon
            size={40}
            style={{ 
              color: theme.palette.primary.main,
              opacity: 0.8
            }}
          />
        </Box>

        <Typography
          variant="h6"
          color="text.primary"
          gutterBottom
          sx={{
            fontWeight: 600,
            textAlign: 'center',
            mb: 1
          }}
        >
          {messages.title}
        </Typography>

        <Divider
          sx={{
            width: 40,
            my: 2,
            borderColor: alpha(theme.palette.primary.main, 0.2),
          }}
        />

        <Typography
          variant="body2"
          color="text.secondary"
          align="center"
          sx={{
            maxWidth: 400,
            lineHeight: 1.6,
          }}
        >
          {messages.subtitle}
        </Typography>
      </Paper>
    </Fade>
  );
};

export default EndOfArticles;