// MobileAppBar.jsx
import React from 'react';
import {
    AppBar,
    Toolbar,
    IconButton,
    Typography
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';

const MobileAppBar = ({ 
    open, 
    drawerWidth, 
    handleDrawerToggle,  
}) => {
    return (
        <AppBar 
            position="fixed" 
            sx={{
                width: '100%', // Utilisez 100% pour prendre toute la largeur disponible
                ...(open && { 
                    width: `calc(100% - ${drawerWidth}px)` 
                }),
                transition: 'width 0.3s', // Ajoutez une transition pour un effet plus fluide
                '@media (max-width: 600px)': { // Media query pour les petits écrans
                    width: '100%', // Utilisez 100% pour prendre toute la largeur disponible
                }
            }}
        >
            <Toolbar>
                <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    edge="start"
                    onClick={handleDrawerToggle}
                    sx={{ 
                        mr: 2, 
                        ...(open && { display: 'none' }) 
                    }}
                >
                    <MenuIcon />
                </IconButton>
                <Typography 
                    variant="h6" 
                    noWrap 
                    component="div" 
                    sx={{ flexGrow: 1 }}
                >
                    Newsfeed
                </Typography>
{/*                 <IconButton 
                    color="inherit" 
                    onClick={toggleTheme}
                >
                    {isDarkMode ? <Brightness7Icon /> : <Brightness4Icon />}
                </IconButton> */}
            </Toolbar>
        </AppBar>
    );
};

export default MobileAppBar;