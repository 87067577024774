import React from 'react';
import { Card, CardContent, Typography, Box } from '@mui/material';
import profiles from '../../variables/profiles';

const MetricCard = ({
  title,
  profile,
  value,
  change,
  comparisonText,
  icon: IconComponent,
  iconColor = 'primary',
  currentMonthArticles,
  previousMonthArticles
}) => {
  const profileLabel = profile !== undefined ? profiles.find(p => p.value === profile.toString()) : null;
  const isPositive = change >= 0;

  return (
    <Card sx={{
      minWidth: { xs: 300, sm: 412 },
      maxWidth: { xs: 400, sm: 600 },
      boxShadow: 3,
      m: { xs: 1, sm: 2 }
    }}>
      <CardContent>
        <Typography variant="h5" component="div" color="text.secondary" gutterBottom>
          {title}
        </Typography>
        {profileLabel && profile > '0' && (
          <Typography variant="subtitle1" component="div" color="text.secondary" gutterBottom>
            Profile: {profileLabel.label}
          </Typography>
        )}
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
          <Typography variant="h3" component="div" color="text.secondary" sx={{ fontWeight: 'bold', mr: 2 }}>
            {value}
          </Typography>
          {IconComponent && <IconComponent color={iconColor} sx={{ fontSize: { xs: 40, sm: 60 } }} />}
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography
            variant="h6"
            color={isPositive ? 'success.main' : 'error.main'}
            sx={{ display: 'flex', alignItems: 'center', mr: 2 }}
          >
            {!isNaN(change) && (
              <>
                {isPositive ? '↑' : '↓'} {Math.abs(change)}%
              </>
            )}
          </Typography>
          <Typography variant="h6" color="text.secondary">
          {comparisonText}
          </Typography>
        </Box>
        {(currentMonthArticles !== undefined && previousMonthArticles !== undefined) && (
          <Typography variant="body2" color="text.secondary" sx={{ mt: 2 }}>
            This month: {currentMonthArticles} | Same day last month: {previousMonthArticles}
          </Typography>
        )}
      </CardContent>
    </Card>
  );
};

export default MetricCard;