import React, { useState, useCallback } from 'react';
import {
    Box,
    Typography,
    Button,
    TextField,
    Select,
    MenuItem,
    InputAdornment,
    Paper,
    Stack,
    Avatar,
    IconButton,
    useTheme,
    alpha
} from '@mui/material';
import {
    Mail as EmailIcon,
    User as PersonIcon,
    Globe as GlobeIcon,
    Edit as EditIcon,
    Languages as LanguageIcon,
    Shield as ShieldIcon,
    LucideGlobe as Globe
} from 'lucide-react';
import CountrySelect from './CountrySelect';
import ImageUploadDialog from './ImageUploadDialog';
import ApiService from '../../services/api';
import { useSession } from '../../contexts/SessionContext';
import { useSnackbar } from '../../contexts/SnackbarContext';

const ProfileField = ({ label, children }) => (
    <Box sx={{ mb: 3 }}>
        <Typography
            variant="subtitle2"
            sx={{
                mb: 1,
                fontWeight: 500,
                color: 'text.secondary',
                display: 'flex',
                alignItems: 'center',
                gap: 1
            }}
        >
            {label}
        </Typography>
        {children}
    </Box>
);

// Fonction utilitaire pour construire l'URL complète de l'image
const getImageUrl = (imageUrl) => {
    if (!imageUrl) return '';

    // Si l'URL est déjà complète
    if (imageUrl.startsWith('http')) {
        return imageUrl;
    }

    // Nettoyer l'URL de toute référence à /media/
    const cleanPath = imageUrl.replace(/^\/?(media\/)?/, '');
    return `${process.env.REACT_APP_PROFILE_IMAGES_URL}${cleanPath}`;
};


const ProfileSection = ({ onSubmit, formData, onInputChange, onCountryChange, onImageUpdate, isSubmitting }) => {
    const [uploadDialogOpen, setUploadDialogOpen] = useState(false);
    const [isUploading, setIsUploading] = useState(false);
    const theme = useTheme();
    const api = ApiService();
    const REACT_APP_PROFILE_IMAGES_URL = process.env.REACT_APP_PROFILE_IMAGES_URL;
    const { user, updateProfileImageUrl } = useSession();
    const { showSnackbar } = useSnackbar();

    async function handleImageUpload(file) {
        try {
            setIsUploading(true);
            const formData = new FormData();
            formData.append('file', file);

            const response = await api.profile.updateImage(formData);
            console.log('Upload response:', response); // Debug

            // Mettre à jour avec l'URL relative
            onImageUpdate(response.image_url);

            // Mettre à jour le contexte de session
            updateProfileImageUrl(response.image_url);
            
            // Fermer la boîte de dialogue
            setUploadDialogOpen(false);
        } catch (error) {
            showSnackbar('Error uploading image', 'error');
            console.error('Error uploading image:', error);
            throw error;
        } finally {
            setIsUploading(false);
        }
    }

    // Obtenir l'URL complète de l'image
    const profileImageUrl = getImageUrl(formData.profile_image_url);
    return (
        <Paper
            elevation={0}
            sx={{
                border: `1px solid ${theme.palette.divider}`,
                borderRadius: 3,
                overflow: 'hidden'
            }}
        >
            <Box
                sx={{
                    p: 3,
                    borderBottom: `1px solid ${theme.palette.divider}`,
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center'
                }}
            >
                <Typography variant="h6" fontWeight="bold">
                    Your Profile
                </Typography>
            </Box>

            <Box sx={{ p: 3 }}>
                <form onSubmit={onSubmit}>
                    <Box sx={{ display: 'flex', gap: 4 }}>
                        {/* Section Image Profil */}
                        <Box sx={{ width: 240 }}>
                            <Box
                                sx={{
                                    position: 'relative',
                                    width: 180,
                                    height: 180,
                                    borderRadius: '50%',
                                    overflow: 'hidden',
                                    mb: 2,
                                    border: `4px solid ${theme.palette.background.paper}`,
                                    boxShadow: theme.shadows[2],
                                }}
                            >
                                <Avatar
                                    src={getImageUrl(formData.profile_image_url)}
                                    alt={formData.firstname}
                                    sx={{
                                        width: '100%',
                                        height: '100%',
                                        opacity: isUploading ? 0.5 : 1,
                                        transition: 'opacity 0.3s'
                                    }}
                                >
                                    {formData.firstname?.charAt(0)?.toUpperCase() || 'U'}
                                </Avatar>

                                {/* Overlay pour le bouton d'édition */}
                                <Box
                                    sx={{
                                        position: 'absolute',
                                        top: 0,
                                        left: 0,
                                        right: 0,
                                        bottom: 0,
                                        borderRadius: '50%',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        background: 'rgba(0, 0, 0, 0)',
                                        transition: 'all 0.3s ease',
                                        cursor: 'pointer',
                                        '&:hover': {
                                            background: 'rgba(0, 0, 0, 0.5)',
                                            '& .edit-button': {
                                                opacity: 1,
                                                transform: 'translateY(0)',
                                            }
                                        },
                                    }}
                                    onClick={() => !isUploading && setUploadDialogOpen(true)}
                                >
                                    <IconButton
                                        className="edit-button"
                                        disabled={isUploading}
                                        sx={{
                                            bgcolor: 'background.paper',
                                            opacity: 0,
                                            transform: 'translateY(10px)',
                                            transition: 'all 0.3s ease',
                                            '&:hover': {
                                                bgcolor: alpha(theme.palette.primary.main, 0.1)
                                            }
                                        }}
                                    >
                                        <EditIcon size={18} />
                                    </IconButton>
                                </Box>
                            </Box>
                        </Box>

                        <ImageUploadDialog
                            open={uploadDialogOpen}
                            onClose={() => setUploadDialogOpen(false)}
                            onUpload={handleImageUpload}
                            isUploading={isUploading}
                        />

                        {/* Form Fields Section */}
                        <Box sx={{ flex: 1 }}>
                            <Stack spacing={3}>
                                {/* Name Fields */}
                                <Box sx={{ display: 'flex', gap: 2 }}>
                                    <ProfileField label="First Name">
                                        <TextField
                                            fullWidth
                                            name="firstname"
                                            value={formData.firstname}
                                            onChange={onInputChange}
                                            size="small"
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <PersonIcon size={18} />
                                                    </InputAdornment>
                                                ),
                                            }}
                                            sx={{
                                                '& .MuiOutlinedInput-root': {
                                                    borderRadius: 2,
                                                }
                                            }}
                                        />
                                    </ProfileField>
                                    <ProfileField label="Last Name">
                                        <TextField
                                            fullWidth
                                            name="lastname"
                                            value={formData.lastname}
                                            onChange={onInputChange}
                                            size="small"
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <PersonIcon size={18} />
                                                    </InputAdornment>
                                                ),
                                            }}
                                            sx={{
                                                '& .MuiOutlinedInput-root': {
                                                    borderRadius: 2,
                                                }
                                            }}
                                        />
                                    </ProfileField>
                                </Box>

                                {/* Email Field */}
                                <ProfileField label="Email">
                                    <TextField
                                        fullWidth
                                        name="email"
                                        value={formData.email}
                                        onChange={onInputChange}
                                        size="small"
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <EmailIcon size={18} />
                                                </InputAdornment>
                                            ),
                                        }}
                                        sx={{
                                            '& .MuiOutlinedInput-root': {
                                                borderRadius: 2,
                                            }
                                        }}
                                    />
                                </ProfileField>

                                {/* Country Field */}
                                <ProfileField label="Country">
                                    <CountrySelect
                                        value={formData.country}
                                        onChange={(e) => onCountryChange(e.target.value)}
                                        size="small"
                                        fullWidth
                                        startAdornment={
                                            <InputAdornment position="start">
                                                <Globe size={18} />
                                            </InputAdornment>
                                        }
                                        sx={{
                                            borderRadius: 2,
                                        }}
                                    />
                                </ProfileField>

                                {/* Profile Settings */}
                                <Box sx={{ display: 'flex', gap: 2 }}>
                                    <ProfileField label="Default Profile for Articles">
                                        <Select
                                            fullWidth
                                            name="default_profile"
                                            value={formData.default_profile}
                                            onChange={onInputChange}
                                            size="small"
                                            startAdornment={
                                                <InputAdornment position="start">
                                                    <ShieldIcon size={18} />
                                                </InputAdornment>
                                            }
                                            sx={{
                                                borderRadius: 2,
                                            }}
                                        >
                                            <MenuItem value="0">All profiles</MenuItem>
                                            <MenuItem value="1">CISO</MenuItem>
                                            <MenuItem value="2">Cybersecurity Expert</MenuItem>
                                            <MenuItem value="3">Incident Response</MenuItem>
                                            <MenuItem value="4">Security Analyst</MenuItem>
                                        </Select>
                                    </ProfileField>

                                    <ProfileField label="Default Language for Articles">
                                        <Select
                                            fullWidth
                                            name="default_language"
                                            value={formData.default_language}
                                            onChange={onInputChange}
                                            size="small"
                                            startAdornment={
                                                <InputAdornment position="start">
                                                    <LanguageIcon size={18} />
                                                </InputAdornment>
                                            }
                                            sx={{
                                                borderRadius: 2,
                                            }}
                                        >
                                            <MenuItem value="en">English</MenuItem>
                                            <MenuItem value="fr">French</MenuItem>
                                        </Select>
                                    </ProfileField>
                                </Box>
                            </Stack>
                        </Box>
                    </Box>
                </form>
            </Box>

            <Box
                sx={{
                    p: 3,
                    borderTop: `1px solid ${theme.palette.divider}`,
                    display: 'flex',
                    justifyContent: 'flex-end',
                    gap: 2,
                    bgcolor: alpha(theme.palette.primary.main, 0.02)
                }}
            >
                <Button
                    variant="outlined"
                    color="inherit"
                    sx={{
                        borderRadius: 2,
                        px: 3
                    }}
                >
                    Cancel
                </Button>
                <Button
                    variant="contained"
                    type="submit"
                    disabled={isSubmitting} // Désactiver pendant la soumission
                    sx={{
                        borderRadius: 2,
                        px: 3
                    }}
                >
                    Save Changes
                </Button>
            </Box>
        </Paper>
    );
};

export default ProfileSection;