import React, { useState, useEffect, useContext } from 'react';
import { Card, CardContent, Skeleton } from '@mui/material';
import { ErrorOutline } from '@mui/icons-material';
import { useAppSettings } from '../../contexts/AppSettingsContext';
import MetricCard from './MetricCard';
import ApiService from '../../services/api';
import ErrorMessage from '../ErrorMessage';
import { useSession } from '../../contexts/SessionContext';

const CriticalArticlesCard = () => {
  const [criticalData, setCriticalData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const api = ApiService();
  const {selectedPeriod,setSelectedPeriod, defaultProfile}  = useAppSettings(); 
  const lastlogin = localStorage.getItem('lastLogin');
  const { session } = useSession();

  useEffect(() => {
    const fetchCriticalData = async () => {
      try {
        setLoading(true);
        const params = isNaN(selectedPeriod) 
          ? { last_login: lastlogin } 
          : { days: selectedPeriod };

        if (session.userProfile.defaultProfile !== undefined && session.userProfile.defaultProfile > 0) {
          params.profile = session.userProfile.defaultProfile;
        }
        
        const response = await api.get('/stats/critical', params );
        setCriticalData(response.data);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchCriticalData();
  }, [selectedPeriod, lastlogin, session.userProfile.defaultProfile]);

  if (loading) {
    return (
      <Card sx={{ minWidth: 400, maxWidth: 600, boxShadow: 3 }}>
        <CardContent>
          <Skeleton variant="text" width="60%" />
          <Skeleton variant="rectangular" height={150} />
          <Skeleton variant="text" width="80%" />
        </CardContent>
      </Card>
    );
  }
  if (error) {
    return <ErrorMessage message={error.message} />;
  }
  if (!criticalData) return null;

  return (
    <MetricCard
      title="VERY HIGH ARTICLES"
      profile={session.userProfile.defaultProfile}
      value={criticalData.count}
      change={criticalData.change}
      comparisonText={isNaN(selectedPeriod) ? "Since last login" : `Last ${selectedPeriod} days`}
      icon={ErrorOutline}
      iconColor="error"
      currentMonthArticles={criticalData.current_month}
      previousMonthArticles={criticalData.previous_month}
    />
  );
};

export default CriticalArticlesCard;