import React from 'react';
import { Box, Avatar, Typography, IconButton, Tooltip } from '@mui/material';
import LogoutRoundedIcon from '@mui/icons-material/LogoutRounded';
import { Shield } from 'lucide-react';
import { ShieldCheck } from 'lucide-react';


const UserSection = ({ firstname, lastname, email, profileImageURL, handleExitClick, isAdmin, isTester }) => (
  
  <Box sx={{ mt: 'auto', p: 2 }}>
    {/* User Info Container */}
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        mb: 0,
        gap: 1, // Consistent spacing
      }}
    >
      {/* Avatar */}
      <Avatar
        alt={firstname.charAt(0)}
        src={profileImageURL}
        sx={{ 
          flexShrink: 0, // Prevent avatar from shrinking
        }}
      />
      
      {/* User Details - Will shrink if needed */}
      <Box
        sx={{
          minWidth: 0, // Important for text overflow to work
          flex: 1, // Take available space
        }}
      >
        {/* Name */}
        <Typography 
          variant="subtitle1"
          sx={{
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          {firstname} {lastname.charAt(0)}.
        </Typography>



        {/* Email */}
        <Typography 
          variant="body2" 
          color="text.secondary"
          sx={{
            fontSize: '0.6rem',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          {email}
        </Typography>
      </Box>

      {/* Logout Button - Won't shrink */}
      <IconButton 
        onClick={handleExitClick}
        sx={{ 
          flexShrink: 0, // Prevent button from shrinking
          ml: 0.5, // Small margin for separation
        }}
      >
        <LogoutRoundedIcon />
      </IconButton>
      
    </Box>

    {/* AI Disclaimer */}
    <Typography 
      variant="body2" 
      color="text.secondary" 
      sx={{ 
        fontSize: '0.75rem', 
        mt: 1,
        textAlign: 'left'
      }}
    >
      AI can make mistakes.
    </Typography>
  </Box>
);

export default UserSection;