// keycloak.js
import Keycloak from 'keycloak-js';

const keycloakConfig = {
  url: process.env.REACT_APP_KEYCLOAK_URL,
  realm: process.env.REACT_APP_KEYCLOAK_REALM,
  clientId: process.env.REACT_APP_KEYCLOAK_CLIENT_ID,
  flow: 'standard', // use standard flow
  responseType: 'code', // use code flow
  pkceMethod: 'S256', // public key code exchange method
};

// Créer une seule instance de Keycloak
const keycloak = new Keycloak(keycloakConfig);

export default keycloak;
