import React, { useMemo, useState, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppSettings } from '../../contexts/AppSettingsContext';
import { Text } from '@visx/text';
import { scaleLog } from '@visx/scale';
import { Wordcloud } from '@visx/wordcloud';
import ApiService from '../../services/api';
import { useTheme } from '@mui/material/styles';
import { Card, CardContent, Typography, Box, CircularProgress, Skeleton } from '@mui/material';
import ErrorMessage from '../ErrorMessage';


const WordcloudCard = () => {
  const [keywords, setKeywords] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const lastlogin = localStorage.getItem('lastLogin');
  const api = ApiService();
  const {selectedPeriod,setSelectedPeriod, setSearchTerm, setColorFilter, setProfile}  = useAppSettings(); 
  const navigate = useNavigate();
  const theme = useTheme();
  const colors = theme.palette.wordcloudColors;


  const fontScale = useMemo(() => {
    if (keywords.length === 0) return () => 0;
    return scaleLog({
      domain: [Math.min(...keywords.map((w) => w.value)), Math.max(...keywords.map((w) => w.value))],
      range: [20, 80], // Augmenter la taille maximale des mots
    });
  }, [keywords]);

  const fontSizeSetter = (datum) => fontScale(datum.value);

  const fetchData = async () => {
    try {
      setIsLoading(true);
      let params = {};
      if (isNaN(selectedPeriod)) {
        params.last_login = lastlogin;
      } else {
        params.days = selectedPeriod;
      }
      const response = await api.get('/stats/top_keywords', params);
      setKeywords(response.data);
      setIsLoading(false);
    } catch (err) {
      setError('Une erreur est survenue lors du chargement des données.');
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [selectedPeriod, lastlogin]);

  const handleWordClick = (word) => {
    setColorFilter(['']);
    setSearchTerm(word.text);
    navigate('/newsfeed');
    //setProfile(0);
  };

  const getColor = (i) => {
    return colors[i % colors.length];
  };

  if (isLoading) {
    return (
      <Card sx={{ minWidth: 450, maxWidth: 850, boxShadow: 3 }}>
        <CardContent>
          <Skeleton variant="text" width="60%" />
          <Skeleton variant="rectangular" height={400} />
          <Skeleton variant="text" width="80%" />
        </CardContent>
      </Card>
    );
  }
  if (error) {
    return <ErrorMessage message={error.message} />;
  }

  return (
    <Card sx={{ width: '100%', height: '100%', minHeight: 500 }}> {/* Augmenter la hauteur minimale */}
      <CardContent sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
      <Typography variant="h5" component="div" color="text.secondary" gutterBottom>
          TOP KEYWORDS
        </Typography>
        {isLoading ? (
          <Box display="flex" justifyContent="center" alignItems="center" flexGrow={1}>
            <CircularProgress />
          </Box>
        ) : error ? (
          <Typography color="error">{error}</Typography>
        ) : (
          <Box flexGrow={1} width="100%" height="calc(100% - 40px)"> {/* Utiliser tout l'espace disponible */}
            <Wordcloud
              words={keywords}
              width={870} // Augmenter la largeur
              height={400} // Augmenter la hauteur
              fontSize={fontSizeSetter}
              font="Impact"
              padding={2}
              spiral="rectangular"
              rotate={0}
              random={() => 0.5}
            >
              {(cloudWords) =>
                cloudWords.map((w, i) => (
                  <Text
                    key={w.text}
                    fill={getColor(i)}
                    textAnchor="middle"
                    transform={`translate(${w.x}, ${w.y}) rotate(${w.rotate})`}
                    fontSize={w.size}
                    onClick={() => handleWordClick(w)}
                    style={{ cursor: 'pointer' }}
                    fontFamily={w.font}
                  >
                    {w.text}
                  </Text>
                ))
              }
            </Wordcloud>
          </Box>
        )}
      </CardContent>
    </Card>
  );
};

export default WordcloudCard;