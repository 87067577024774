import React, { useState } from 'react';
import {
  Paper,
  Typography,
  TextField,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  Stack,
  Chip,
  Alert,
} from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import dayjs from 'dayjs';
import {
  Clock,
  Info,
  Sparkles,
  Shield,
  Save,
} from 'lucide-react';
import ApiService from '../../services/api';

// Configuration pour dayjs (optionnel)
import 'dayjs/locale/fr';
dayjs.locale('fr');

const MESSAGE_TYPES = [
  { value: 'maintenance', label: 'Maintenance', icon: <Clock size={18} /> },
  { value: 'announcement', label: 'Announcement', icon: <Info size={18} /> },
  { value: 'feature', label: 'New Feature', icon: <Sparkles size={18} /> },
  { value: 'security', label: 'Security', icon: <Shield size={18} /> },
  { value: 'other', label: 'Other', icon: <Info size={18} /> }
];

const PRIORITIES = [
  { value: 'low', label: 'Low', color: 'success' },
  { value: 'medium', label: 'Medium', color: 'warning' },
  { value: 'high', label: 'High', color: 'error' }
];

const CreateMessageForm = () => {
  const api = ApiService();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  
  const [formData, setFormData] = useState({
    type: 'announcement',
    priority: 'medium',
    title: '',
    content: '',
    start_date: dayjs(),
    end_date: null
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleDateChange = (field, value) => {
    setFormData(prev => ({
      ...prev,
      [field]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);
    setSuccess(false);

    try {
      // Préparer les données pour l'API
      const messageData = {
        ...formData,
        start_date: formData.start_date.toISOString(),
        end_date: formData.end_date ? formData.end_date.toISOString() : null
      };

      await api.post('/messages', messageData);
      
      setSuccess(true);
      // Réinitialiser le formulaire
      setFormData({
        type: 'announcement',
        priority: 'medium',
        title: '',
        content: '',
        start_date: dayjs(),
        end_date: null
      });
    } catch (err) {
      setError(err.response?.data?.detail || 'An error occurred while creating the message');
    } finally {
      setLoading(false);
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Paper elevation={0} sx={{ p: 3, border: '1px solid', borderColor: 'divider' }}>
        <Typography variant="h6" gutterBottom sx={{ mb: 3 }}>
          Create System Message
        </Typography>

        {error && (
          <Alert severity="error" sx={{ mb: 3 }}>
            {error}
          </Alert>
        )}

        {success && (
          <Alert severity="success" sx={{ mb: 3 }}>
            Message created successfully!
          </Alert>
        )}

        <form onSubmit={handleSubmit}>
          <Stack spacing={3}>
            <Box sx={{ display: 'flex', gap: 2 }}>
              <FormControl fullWidth size="small">
                <InputLabel>Type</InputLabel>
                <Select
                  name="type"
                  value={formData.type}
                  onChange={handleChange}
                  label="Type"
                >
                  {MESSAGE_TYPES.map(type => (
                    <MenuItem key={type.value} value={type.value}>
                      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                        {type.icon}
                        {type.label}
                      </Box>
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <FormControl fullWidth size="small">
                <InputLabel>Priority</InputLabel>
                <Select
                  name="priority"
                  value={formData.priority}
                  onChange={handleChange}
                  label="Priority"
                >
                  {PRIORITIES.map(priority => (
                    <MenuItem key={priority.value} value={priority.value}>
                      <Chip
                        size="small"
                        label={priority.label}
                        color={priority.color}
                        variant="outlined"
                      />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>

            <TextField
              fullWidth
              label="Title"
              name="title"
              value={formData.title}
              onChange={handleChange}
              required
              size="small"
            />

            <TextField
              fullWidth
              label="Content"
              name="content"
              value={formData.content}
              onChange={handleChange}
              required
              multiline
              rows={4}
              size="small"
            />

            <Box sx={{ display: 'flex', gap: 2 }}>
              <DateTimePicker
                label="Start Date"
                value={formData.start_date}
                onChange={(newValue) => handleDateChange('start_date', newValue)}
                slotProps={{ textField: { size: 'small', fullWidth: true, required: true } }}
              />

              <DateTimePicker
                label="End Date (Optional)"
                value={formData.end_date}
                onChange={(newValue) => handleDateChange('end_date', newValue)}
                slotProps={{ textField: { size: 'small', fullWidth: true } }}
              />
            </Box>

            <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 2, mt: 2 }}>
              <Button
                variant="outlined"
                onClick={() => {
                  setFormData({
                    type: 'announcement',
                    priority: 'medium',
                    title: '',
                    content: '',
                    start_date: dayjs(),
                    end_date: null
                  });
                  setError(null);
                  setSuccess(false);
                }}
                disabled={loading}
              >
                Reset
              </Button>
              <Button
                type="submit"
                variant="contained"
                startIcon={<Save size={18} />}
                disabled={loading}
              >
                Create Message
              </Button>
            </Box>
          </Stack>
        </form>
      </Paper>
    </LocalizationProvider>
  );
};

export default CreateMessageForm;