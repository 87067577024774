import {
    Newspaper,
    Sparkles,
    Bell,
    Search,
    MessageSquare,
    Share2,
    BookMarked,
    Gauge,
    Shield,
    Star
} from 'lucide-react';
import { Box, Typography, Stack, Tooltip } from '@mui/material';

const PlanFeature = ({ icon: Icon, text, tooltip }) => (
               
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 1.5,
                '&:hover': {
                    '& .feature-text': {
                        color: 'primary.main',
                    },
                    '& .feature-icon': {
                        transform: 'scale(1.1)',
                    }
                }
            }}
        >
            <Icon
                size={20}
                className="feature-icon"
                style={{
                    transition: 'transform 0.2s ease-in-out',
                    color: 'var(--mui-palette-primary-main)'
                }}
            />
            <Tooltip title={tooltip} arrow placement="right-start">
            <Typography
                variant="body2"
                className="feature-text"
                sx={{
                    transition: 'color 0.2s ease-in-out'
                }}
            >
                {text}
            </Typography>
            </Tooltip>
        </Box>


);

const PlanFeatures = () => (
    <Box sx={{ mt: 4 }}>
        <Typography
            variant="subtitle1"
            fontWeight="medium"
            gutterBottom
            sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 1,
                '&::after': {
                    content: '""',
                    flex: 1,
                    height: '1px',
                    bgcolor: 'divider'
                }
            }}
        >
            Your Subscription Benefits:
        </Typography>
        <Stack spacing={2} sx={{ mt: 2 }}>
            <PlanFeature
                icon={Newspaper}
                text="Full Access to Cybersecurity News"
                tooltip="Stay up-to-date with unlimited access to industry-leading cybersecurity news."
            />
            <PlanFeature
                icon={Sparkles}
                text="AI-Powered Summaries"
                tooltip="Save time with quick, intelligent summaries that get to the heart of complex articles."
            />
            <PlanFeature
                icon={Search}
                text="Advanced Search & Filters"
                tooltip="Easily find specific topics, trends, or incidents relevant to your role."
            />
            {/* <PlanFeature 
          icon={Bell} 
          text="Custom Watchlists"
          tooltip="Create and manage custom alerts for topics that matter to you"
        />*/}
        <PlanFeature 
          icon={Shield}
          text="Content Customization by Role"
          tooltip="Receive strategic insights as a CISO or technical details as a cybersecurity expert."
        /> 
            <PlanFeature
                icon={Share2}
                text="Team Collaboration"
                tooltip="Share critical insights and articles with your team for better collaboration."
            />
            <PlanFeature
                icon={Star}
                text="And More Features Coming Soon"
                tooltip="Additional functionalities in development to enhance your experience."
            />
        </Stack>
    </Box>
);

export default PlanFeatures;