import React, { useCallback, useMemo } from 'react';
import {
  Box,
  Typography,
  Chip,
  IconButton,
  Divider,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Link,
  Tooltip,
  Collapse,
  alpha,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbUpIconBorderIcon from '@mui/icons-material/ThumbUpAltOutlined';
import ShareButton from './ShareButton';
import profileIcons from '../variables/profileIcons';
import { getImportance } from '../variables/importanceOptions';
import useUserTimezone from '../components/Time/useUserTimeZone';

// Configuration des tooltips
const POPPER_PROPS = {
  modifiers: [
    {
      name: 'offset',
      options: {
        offset: [0, -8],
      },
    },
  ],
};

const ArticleComponent = React.memo(({
  article,
  isLiked,
  onLikeToggle,
  isRead,
  onReadToggle,
  isReadLater,
  onReadLaterToggle,
  expanded,
  onExpandChange,
  cardColor,
  filters,
  language = 'en'
}) => {
  const theme = useTheme();
  const userTimezone = useUserTimezone();

  // Fonction pour mettre en surbrillance le texte recherché
  const highlightText = useCallback((text, keyword) => {
    if (!keyword) return text;
    const highlightColor = theme.palette.mode === 'dark' ? '#FFD700' : '#FFFACD';
    const regex = new RegExp(`(${keyword})`, 'gi');
    return text.replace(regex, `<span style="background-color: ${highlightColor}; font-weight: bold;">$1</span>`);
  }, [theme.palette.mode]);

  // Handler pour les clics sur les icônes avec effet visuel
  const handleIconClick = useCallback((e, action) => {
    e.stopPropagation();
    const button = e.currentTarget;
    
    // Ajouter un effet de pulsation
    button.style.transform = 'scale(0.8)';
    setTimeout(() => {
      button.style.transform = 'scale(1)';
    }, 100);
    
    action();
  }, []);

  // Mémoisation des titres traduits
  const translatedTitles = useMemo(() => ({
    mainPoints: language === 'fr' ? 'Points principaux:' : 'Main points:',
    takeaways: language === 'fr' ? 'À retenir:' : 'Takeaways:',
  }), [language]);


  // Détermine si l'article doit être masqué ou juste marqué comme lu
  const shouldHideArticle = isRead && filters.selectedView !== 'all';

  return (
    <Collapse
      in={!shouldHideArticle}  // Modification ici
      timeout={400}
      sx={{
        '& .MuiCollapse-wrapperInner': {
          transition: theme => theme.transitions.create(['opacity', 'transform'], {
            duration: 400,
            easing: theme.transitions.easing.easeInOut
          }),
          opacity: shouldHideArticle ? 0 : 1,
          transform: shouldHideArticle ? 'translateY(-10px)' : 'translateY(0)',
        }
      }}
    >
      <Accordion
        expanded={expanded}
        onChange={onExpandChange}
        sx={{
          boxShadow: 3,
          '&:before': { display: 'none' },
          '&:hover': { boxShadow: 6 },
          width: '100%',
          mb: 2, // Ajout d'une marge en bas fixe
          transition: 'box-shadow 0.3s ease-in-out',
          //minHeight: !expanded ? '200px' : 'auto', // Hauteur minimale quand non développé
          // Ajout d'un style pour les articles lus
          ...(isRead && {
            opacity: 0.8,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark'
                ? alpha(theme.palette.background.paper, 0.6)
                : alpha(theme.palette.background.default, 0.6),
            '& .MuiTypography-root': {
              color: (theme) => alpha(theme.palette.text.primary, 0.7),
            }
          })
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls={`panel-${article.article_id}-content`}
          id={`panel-${article.article_id}-header`}
        >
          <Box sx={{ width: '100%' }}>
            {/* En-tête de l'article */}
            <Box display="flex" alignItems="center" justifyContent="space-between" mb={1}>
              <Box display="flex" alignItems="center">
                <Box
                  sx={{
                    bgcolor: cardColor,
                    width: 16,
                    height: 16,
                    borderRadius: '50%',
                    mr: 1
                  }}
                />
                <Typography
                  variant="h6"
                  component="div"
                  fontWeight={400}
                  dangerouslySetInnerHTML={{
                    __html: highlightText(article.title, filters.searchTerm)
                  }}
                />
              </Box>
              <Typography variant="caption" color="text.secondary">
                {new Date(article.published_at).toLocaleString(undefined, {
                  dateStyle: 'medium',
                  timeStyle: 'short'
                })}
              </Typography>
            </Box>

            {/* Informations sur la source */}
            <Typography variant="body2" color="text.secondary" sx={{ ml: 3, mb: 0.5 }}>
              {article.feedname} -
              <Link
                href={article.url}
                target="_blank"
                rel="noopener"
                onClick={(e) => e.stopPropagation()}
              >
                {article.url}
              </Link>
            </Typography>

            {/* Résumé de l'article */}
            <Typography
              sx={{ ml: 3, mb: 1 }}
              variant="body1"
              color="textSecondary"
              dangerouslySetInnerHTML={{
                __html: highlightText(article.summary, filters.searchTerm)
              }}
            />

            <Divider sx={{ my: 1 }} />

            {/* Actions et tags */}
            <Box display="flex" justifyContent="space-between" alignItems="center" mt={1}>
              <Box>
                {/* Boutons d'action */}
                <Tooltip title="Like Article" PopperProps={POPPER_PROPS}>
                  <IconButton onClick={(e) => handleIconClick(e, onLikeToggle)}>
                    {isLiked ? <ThumbUpIcon color="primary" /> : <ThumbUpIconBorderIcon />}
                  </IconButton>
                </Tooltip>

                <Tooltip title="Mark as Read" PopperProps={POPPER_PROPS}>
                  <IconButton onClick={(e) => handleIconClick(e, onReadToggle)}>
                    {isRead ? <CheckCircleIcon color="primary" /> : <CheckCircleOutlineIcon />}
                  </IconButton>
                </Tooltip>

                <Tooltip title={isReadLater ? "Remove from Read Later" : "Save for Later"} PopperProps={POPPER_PROPS}>
                  <IconButton 
                    onClick={(e) => handleIconClick(e, onReadLaterToggle)}
                    sx={{
                      position: 'relative',
                      transition: 'transform 0.1s ease-in-out',
                      '&:hover': {
                        transform: 'scale(1.1)',
                      },
                      '& .MuiSvgIcon-root': {
                        transition: 'all 0.2s ease-in-out',
                        transform: isReadLater ? 'scale(1.1)' : 'scale(1)',
                      },
                    }}
                  >
                    {isReadLater ? (
                      <BookmarkIcon 
                        color="primary"
                        sx={{
                          animation: isReadLater ? 'popIn 0.3s ease-out' : 'none',
                          '@keyframes popIn': {
                            '0%': { transform: 'scale(0.6)' },
                            '50%': { transform: 'scale(1.2)' },
                            '100%': { transform: 'scale(1)' }
                          }
                        }}
                      />
                    ) : (
                      <BookmarkBorderIcon />
                    )}
                  </IconButton>
                </Tooltip>

                <ShareButton
                  title={article.title}
                  url={article.url}
                  onClick={(e) => e.stopPropagation()}
                />
              </Box>

              {/* Tags de profil */}
              <Box>
                {article.profiles.map((profile) => {
                  const IconComponent = profileIcons[profile.name];
                  return (
                    <Chip
                      key={profile.id}
                      icon={IconComponent && <IconComponent />}
                      label={profile.name}
                      sx={{ mr: 1, mb: 1 }}
                      color="primary"
                      variant="outlined"
                      size="small"
                    />
                  );
                })}
              </Box>
            </Box>
          </Box>
        </AccordionSummary>

        {/* Contenu détaillé de l'article */}
        <AccordionDetails>
          {/* Points principaux */}
          {article.main_points && (
            <Box mb={2}>
              <Typography variant="subtitle1" gutterBottom>
                {translatedTitles.mainPoints}
              </Typography>
              <ul style={{ marginTop: 0, paddingLeft: '1.5rem' }}>
                {article.main_points.map((point, i) => (
                  <li key={i}>
                    <Typography
                      variant="body1"
                      dangerouslySetInnerHTML={{
                        __html: highlightText(point, filters.searchTerm)
                      }}
                    />
                  </li>
                ))}
              </ul>
            </Box>
          )}

          {/* Points à retenir */}
          {article.takeaways && (
            <Box mb={2}>
              <Typography variant="subtitle1" gutterBottom>
                {translatedTitles.takeaways}
              </Typography>
              <ul style={{ marginTop: 0, paddingLeft: '1.5rem' }}>
                {article.takeaways.map((takeaway, i) => (
                  <li key={i}>
                    <Typography
                      variant="body1"
                      dangerouslySetInnerHTML={{
                        __html: highlightText(takeaway, filters.searchTerm)
                      }}
                    />
                  </li>
                ))}
              </ul>
            </Box>
          )}

          {/* Mots-clés */}
          {article.keywords && (
            <Box mb={2}>
              <Typography variant="subtitle1" gutterBottom>
                Keywords:
              </Typography>
              <Box display="flex" flexWrap="wrap" gap={1}>
                {article.keywords.map((keyword, i) => (
                  <Chip
                    key={i}
                    label={keyword.keyword}
                    size="small"
                    color="default"
                    variant="outlined"
                  />
                ))}
              </Box>
            </Box>
          )}
        </AccordionDetails>
      </Accordion>
    </Collapse>
  );
});

ArticleComponent.displayName = 'ArticleComponent';

export default ArticleComponent;