import React, { useState } from 'react';
import { IconButton, Menu, MenuItem, ListItemIcon, ListItemText, Tooltip } from '@mui/material';
import ShareIcon from '@mui/icons-material/Share';
import FacebookIcon from '@mui/icons-material/Facebook';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import EmailIcon from '@mui/icons-material/Email';
import XIcon from '@mui/icons-material/X';

const ShareButton = ({ title, url }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const handleClick = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
    setTooltipOpen(false);
  };

  const handleOptionClick = (event, action) => {
    event.stopPropagation();
    action();
    handleClose();
  };

  const handleClose = (event) => {
    if (event) {
      event.stopPropagation();
    }
    setAnchorEl(null);
  };

  const platformName = "cybersecnews.ai";
  const shareMessage = `${title} - via ${platformName}`;

  const shareOptions = [
    {
      name: 'X',
      icon: <XIcon />,
      action: () => window.open(`https://x.com/intent/tweet?text=${encodeURIComponent(shareMessage)}&url=${encodeURIComponent(url)}`, '_blank'),
    },
    {
      name: 'Facebook',
      icon: <FacebookIcon />,
      action: () => window.open(`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(url)}&quote=${encodeURIComponent(shareMessage)}`, '_blank'),
    },
    {
      name: 'LinkedIn',
      icon: <LinkedInIcon />,
      action: () => window.open(`https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(url)}&title=${encodeURIComponent(shareMessage)}`, '_blank'),
    },
    {
      name: 'Email',
      icon: <EmailIcon />,
      action: () => window.location.href = `mailto:?subject=${encodeURIComponent(shareMessage)}&body=${encodeURIComponent(`Découvrez cet article intéressant sur ${platformName}: ${url}`)}`,
    },
  ];

  return (
    <>
      <Tooltip
        title="Share"
        open={tooltipOpen}
        onClose={() => setTooltipOpen(false)}
        PopperProps={{
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [0, 3],
              },
            },
          ],
        }}
      >
        <IconButton
          aria-label="share"
          onClick={handleClick}
          onMouseEnter={() => setTooltipOpen(true)}
          onMouseLeave={() => setTooltipOpen(false)}
        >
          <ShareIcon />
        </IconButton>
      </Tooltip>

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClick={(event) => event.stopPropagation()}
        onClose={handleClose}
      >
        {shareOptions.map((option) => (
          <MenuItem 
            key={option.name}
            onClick={(event) => handleOptionClick(event, option.action)}
          >
            <ListItemIcon>
              {option.icon}
            </ListItemIcon>
            <ListItemText primary={option.name} />
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default ShareButton;