import React, { useState, useEffect } from 'react';
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  TextField,
  IconButton,
  Typography,
  Chip,
  Menu,
  MenuItem,
  InputAdornment,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Alert,
  useTheme,
  CircularProgress,
  Stack,
} from '@mui/material';
import {
  Search,
  MoreVertical,
  Shield,
  UserX,
  RefreshCw,
  CheckCircle2,
  XCircle,
} from 'lucide-react';
import ApiService from '../../services/api';
import { useSnackbar } from '../../contexts/SnackbarContext';
import UserDetailsDialog from './UserDetailsDialog';

const ConfirmationDialog = ({ open, onClose, onConfirm, title, message, severity = "warning" }) => (
  <Dialog open={open} onClose={onClose}>
    <DialogTitle>{title}</DialogTitle>
    <DialogContent>
      <Alert severity={severity} sx={{ mb: 2 }}>
        {message}
      </Alert>
    </DialogContent>
    <DialogActions>
      <Button onClick={onClose}>Cancel</Button>
      <Button color="error" onClick={onConfirm}>
        Confirm
      </Button>
    </DialogActions>
  </Dialog>
);

const UserManagement = () => {
  const theme = useTheme();
  const api = ApiService();
  const { showSnackbar } = useSnackbar();
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalUsers, setTotalUsers] = useState(0);
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedUser, setSelectedUser] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [confirmAction, setConfirmAction] = useState(null);
  const [detailsDialogOpen, setDetailsDialogOpen] = useState(false);

  const fetchUsers = async () => {
    try {
      setLoading(true);
      const params = {
        page: page + 1,
        per_page: rowsPerPage,
        search: searchQuery || undefined,
        sort_by: 'created_at',
        sort_order: 'desc',
        };
    
      const response = await api.get('/admin/users', params );




      setUsers(response.data.users);
      setTotalUsers(response.data.total);
    } catch (error) {
      showSnackbar('Error loading users', 'error');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchUsers();
  }, [page, rowsPerPage, searchQuery]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleMenuOpen = (event, user) => {
    setAnchorEl(event.currentTarget);
    setSelectedUser(user);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    //setSelectedUser(null);
  };

  const handleViewDetails = (user) => {
    setDetailsDialogOpen(true);
    setSelectedUser(user);
    handleMenuClose();
  };

  const handleDisableUser = async () => {
    try {
      await api.put(`/admin/users/${selectedUser.id}/disable`);
      showSnackbar('User disabled successfully', 'success');
      fetchUsers(); // Refresh the user list
      setDetailsDialogOpen(false);
      setSelectedUser(null);
    } catch (error) {
      showSnackbar('Error disabling user', 'error');
    }
    setConfirmDialogOpen(false);
  };

  return (
    <Box sx={{ p: 3 }}>
      <Paper
        elevation={0}
        sx={{
          borderRadius: 3,
          border: `1px solid ${theme.palette.divider}`,
          overflow: 'hidden',
        }}
      >
        {/* Header */}
        <Box
          sx={{
            p: 3,
            borderBottom: `1px solid ${theme.palette.divider}`,
            backgroundColor: 'background.paper',
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mb: 3 }}>
            <Shield size={24} color={theme.palette.primary.main} />
            <Typography variant="h5" fontWeight="bold">
              User Management
            </Typography>
          </Box>

          <TextField
            fullWidth
            placeholder="Search users..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search size={20} />
                </InputAdornment>
              ),
            }}
            sx={{ maxWidth: 400 }}
          />
        </Box>

        {/* Table */}
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>User</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Roles</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Subscription</TableCell>
                <TableCell>Last Login</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {users.map((user) => (
                <TableRow key={user.id}>
                  <TableCell>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                      <Typography variant="body2" fontWeight="medium">
                        {user.firstname} {user.lastname}
                      </Typography>
                      {!user.enabled && (
                        <Chip
                          size="small"
                          label="Disabled"
                          color="error"
                          variant="outlined"
                        />
                      )}
                    </Box>
                    <Typography variant="caption" color="text.secondary">
                      {user.username}
                    </Typography>
                  </TableCell>
                  <TableCell>{user.email}</TableCell>
                  <TableCell>
                    <Box sx={{ display: 'flex', gap: 0.5, flexWrap: 'wrap' }}>
                      {user.roles.map((role) => (
                        <Chip
                          key={role}
                          label={role}
                          size="small"
                          variant="outlined"
                        />
                      ))}
                    </Box>
                  </TableCell>
                  <TableCell>
                    {user.enabled ? (
                      <Chip
                        icon={<CheckCircle2 size={14} />}
                        label="Active"
                        size="small"
                        color="success"
                      />
                    ) : (
                      <Chip
                        icon={<XCircle size={14} />}
                        label="Disabled"
                        size="small"
                        color="error"
                      />
                    )}
                  </TableCell>
                  <TableCell>
                    <Chip
                      label={user.subscription_status || 'None'}
                      size="small"
                      color={user.subscription_status ? 'primary' : 'default'}
                      variant="outlined"
                    />
                  </TableCell>
                  <TableCell>
                    {user.last_login ? (
                      new Date(user.last_login).toLocaleDateString()
                    ) : (
                      <Typography variant="caption" color="text.secondary">
                        Never
                      </Typography>
                    )}
                  </TableCell>
                  <TableCell>
                    <IconButton
                      size="small"
                      onClick={(event) => handleMenuOpen(event, user)}
                    >
                      <MoreVertical size={16} />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
              {loading && (
                <TableRow>
                  <TableCell colSpan={7} align="center" sx={{ py: 8 }}>
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        gap: 2,
                      }}
                    >
                      <CircularProgress size={24} />
                      <Typography color="text.secondary">
                        Loading users...
                      </Typography>
                    </Box>
                  </TableCell>
                </TableRow>
              )}
              {!loading && users.length === 0 && (
                <TableRow>
                  <TableCell colSpan={7} align="center" sx={{ py: 8 }}>
                    <Box
                      sx={{ maxWidth: 400, mx: 'auto', textAlign: 'center' }}
                    >
                      <Typography variant="h6" gutterBottom>
                        No users found
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        {searchQuery
                          ? 'No users match your search criteria. Try adjusting your search.'
                          : 'There are no users in the system yet.'}
                      </Typography>
                    </Box>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>

        {/* Pagination */}
        <TablePagination
          component="div"
          count={totalUsers}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          rowsPerPageOptions={[5, 10, 25, 50]}
        />

        {/* Actions Menu */}
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
          PaperProps={{
            sx: {
              boxShadow: theme.shadows[3],
              minWidth: 180,
            },
          }}
        >
          <MenuItem onClick={() => handleViewDetails(selectedUser)}>
            <Shield size={18} style={{ marginRight: 8 }} />
            View Details
          </MenuItem>
          <MenuItem
            onClick={() => {
              setConfirmDialogOpen(true);
              setConfirmAction({
                title: 'Disable User Account',
                message: `Are you sure you want to disable ${selectedUser?.firstname} ${selectedUser?.lastname}'s account? They will not be able to log in until the account is re-enabled.`,
                action: handleDisableUser,
              });
            }}
            sx={{ color: 'error.main' }}
          >
            <UserX size={18} style={{ marginRight: 8 }} />
            Disable Account
          </MenuItem>
          <MenuItem>
            <RefreshCw size={18} style={{ marginRight: 8 }} />
            Reset Password
          </MenuItem>
        </Menu>

        {/* Confirmation Dialog */}
        {confirmAction && (
          <ConfirmationDialog
            open={confirmDialogOpen}
            title={confirmAction.title}
            message={confirmAction.message}
            onClose={() => {
              setConfirmDialogOpen(false);
              setConfirmAction(null);
            }}
            onConfirm={() => {
              confirmAction.action();
              setConfirmAction(null);
            }}
            severity="warning"
          />
        )}

        {/* User Details Dialog */}
        {selectedUser && (
          <UserDetailsDialog
            user={selectedUser}
            open={detailsDialogOpen}
            onClose={() => {
              setDetailsDialogOpen(false);
              setSelectedUser(null);
            }}
            onDisableUser={() => {
              setConfirmDialogOpen(true);
              setConfirmAction({
                title: 'Disable User Account',
                message: `Are you sure you want to disable ${selectedUser?.firstname} ${selectedUser?.lastname}'s account?`,
                action: handleDisableUser,
              });
            }}
            onResetPassword={() => {
              // Implement password reset functionality
            }}
          />
        )}
      </Paper>
    </Box>
  );
};

export default UserManagement;
