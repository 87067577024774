// AuthProvider.js
import React from 'react';
import { ReactKeycloakProvider } from '@react-keycloak/web';
import keycloak from './keycloak'; // Importer l'instance Keycloak déjà créée
import { CircularProgress, Box, Typography } from '@mui/material';

const AuthProvider = ({ children }) => {
  // Affichage pendant le chargement de l'initialisation de Keycloak
  const LoadingComponent = (
    <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
      <CircularProgress />
    </Box>
  );

  return (
    <ReactKeycloakProvider
      authClient={keycloak}
      initOptions={{ onLoad: 'login-required', checkLoginIframe: false, }} // Configuration d'initialisation
      LoadingComponent={LoadingComponent}
      onEvent={(event, error) => {
        if (event === 'onAuthError') {
          console.error('Authentication Error:', error);
        }
      }}
    >
      {children}
    </ReactKeycloakProvider>
  );
};

export default AuthProvider;
