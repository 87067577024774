import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Typography,
  Box,
  Chip,
  Tabs,
  Tab,
  useTheme,
  Stack,
  Button,
} from '@mui/material';
import {
  X as CloseIcon,
  Shield,
  Activity,
  CreditCard,
  UserX,
  RefreshCw,
} from 'lucide-react';

const UserDetailsDialog = ({ user, open, onClose, onDisableUser, onResetPassword }) => {
  const theme = useTheme();
  const [currentTab, setCurrentTab] = React.useState(0);

  if (!user) return null;

  const formatDate = (date) => {
    return new Date(date).toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
    });
  };

  const tabs = [
    { label: 'Profile', icon: Shield },
    { label: 'Activity', icon: Activity },
    { label: 'Subscription', icon: CreditCard },
  ];

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      {/* Header */}
      <DialogTitle>
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <Typography variant="h6">User Details</Typography>
          <IconButton onClick={onClose} size="small">
            <CloseIcon size={20} />
          </IconButton>
        </Box>
      </DialogTitle>

      <DialogContent dividers>
        {/* User Info Header */}
        <Box sx={{ mb: 2 }}>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mb: 2 }}>
            <Box
              sx={{
                width: 48,
                height: 48,
                borderRadius: '50%',
                backgroundColor: theme.palette.primary.main,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                color: 'white',
                fontSize: '1.2rem',
                fontWeight: 'bold',
              }}
            >
              {user.firstname?.[0]}
              {user.lastname?.[0]}
            </Box>
            <Box>
              <Typography variant="h6">
                {user.firstname} {user.lastname}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {user.username}
              </Typography>
            </Box>
          </Box>

          <Box sx={{ display: 'flex', gap: 1, flexWrap: 'wrap' }}>
            {user.roles.map((role) => (
              <Chip
                key={role}
                label={role}
                size="small"
                color={role === 'Admin' ? 'error' : 'default'}
                icon={<Shield size={14} />}
              />
            ))}
          </Box>
        </Box>

        {/* Tabs */}
        <Tabs
          value={currentTab}
          onChange={(e, newValue) => setCurrentTab(newValue)}
          variant="fullWidth"
          sx={{ mb: 2 }}
        >
          {tabs.map((tab, index) => (
            <Tab
              key={index}
              label={tab.label}
              icon={<tab.icon size={18} />}
              iconPosition="start"
              sx={{
                minHeight: 48,
                textTransform: 'none',
              }}
            />
          ))}
        </Tabs>

        {/* Tab Content */}
        {currentTab === 0 && (
          <Box>
            <Typography variant="subtitle2" color="text.secondary" gutterBottom>
              Email
            </Typography>
            <Typography variant="body2" gutterBottom>
              {user.email}
            </Typography>

            <Typography variant="subtitle2" color="text.secondary" gutterBottom>
              Status
            </Typography>
            <Chip
              label={user.enabled ? 'Active' : 'Disabled'}
              size="small"
              color={user.enabled ? 'success' : 'error'}
            />

            <Typography variant="subtitle2" color="text.secondary" gutterBottom sx={{ mt: 2 }}>
              Created At
            </Typography>
            <Typography variant="body2" gutterBottom>
              {formatDate(user.created_at)}
            </Typography>

            <Typography variant="subtitle2" color="text.secondary" gutterBottom>
              Last Login
            </Typography>
            <Typography variant="body2">
              {user.last_login ? formatDate(user.last_login) : 'Never'}
            </Typography>
          </Box>
        )}

        {currentTab === 1 && (
          <Box>
            {/* Implement user activity content here */}
            <Typography color="text.secondary">Activity history will be displayed here.</Typography>
          </Box>
        )}

        {currentTab === 2 && (
          <Box>
            {user.subscription_status ? (
              <Box>
                <Chip
                  label={user.subscription_status}
                  color="primary"
                  sx={{ mb: 2 }}
                />
                <Typography variant="body2" color="text.secondary">
                  Valid until: {formatDate(user.subscription_end)}
                </Typography>
              </Box>
            ) : (
              <Typography color="text.secondary">
                No active subscription
              </Typography>
            )}
          </Box>
        )}
      </DialogContent>

      <Box sx={{ p: 2 }}>
        <Stack spacing={1}>
          {user?.enabled && (
            <Button
              fullWidth
              variant="outlined"
              color="error"
              startIcon={<UserX size={18} />}
              onClick={onDisableUser}
            >
              Disable Account
            </Button>
          )}
          <Button
            fullWidth
            variant="outlined"
            startIcon={<RefreshCw size={18} />}
            onClick={onResetPassword}
          >
            Reset Password
          </Button>
        </Stack>
      </Box>
    </Dialog>
  );
};

export default UserDetailsDialog;
