import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Button,
  Card,
  CardContent,
  Divider,
  Alert,
  CircularProgress,
  Paper,
  Stack,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions
} from '@mui/material';
import {
  CreditCard,
  CheckCircle,
  Zap,
  Shield,
  Bell,
  Download,
  AlertCircle,
  Calendar,
  RefreshCw
} from 'lucide-react';
import ApiService from '../../services/api';
import PriceCard from './PriceCard';
import { useTheme } from '@mui/material/styles';


const SubscriptionDialog = ({ open, onClose, onConfirm, title, message, confirmText, confirmColor = "error" }) => (
  <Dialog open={open} onClose={onClose}>
    <DialogTitle>{title}</DialogTitle>
    <DialogContent>
      <Typography>{message}</Typography>
    </DialogContent>
    <DialogActions>
      <Button onClick={onClose}>Cancel</Button>
      <Button
        onClick={onConfirm}
        color={confirmColor}
        variant="contained"
      >
        {confirmText}
      </Button>
    </DialogActions>
  </Dialog>
);


const BillingHistory = ({ invoices }) => (
  <Paper elevation={0} sx={{ border: 1, borderColor: 'divider', borderRadius: 3 }}>
    <CardContent>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: 3 }}>
        <Typography variant="h6" fontWeight="bold">
          Billing History
        </Typography>
        <CheckCircle color="success" size={20} />
      </Box>
      <Stack spacing={2}>
        {invoices.map((invoice) => (
          <Paper
            key={invoice.id}
            elevation={0}
            sx={{
              p: 2,
              border: 1,
              borderColor: 'divider',
              borderRadius: 2,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between'
            }}
          >
            <Box>
              <Typography variant="subtitle2" fontWeight="medium">
                {new Date(invoice.created * 1000).toLocaleDateString()}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                ${(invoice.amount_paid / 100).toFixed(2)} USD
              </Typography>
            </Box>
            <Button
              variant="outlined"
              size="small"
              startIcon={<Download size={16} />}
              onClick={() => window.open(invoice.invoice_pdf, '_blank')}
            >
              Download
            </Button>
          </Paper>
        ))}
      </Stack>
    </CardContent>
  </Paper>
);




const BillingSection = () => {
  const [subscriptionData, setSubscriptionData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [invoices, setInvoices] = useState([]);
  const api = ApiService();
  const theme = useTheme();

  const fetchSubscriptionData = async () => {
    try {
      const response = await api.get('/subscription/status');
      setSubscriptionData(response.data);
    } catch (error) {
      if (error.response?.status !== 404) {
        setError('Failed to load subscription data');
      }
      console.error('Error fetching subscription:', error);
    } finally {
      setLoading(false);
    }
  };

  const fetchInvoices = async () => {
    try {
      const response = await api.get('/subscription/invoices');
      setInvoices(response.data);
    } catch (error) {
      console.error('Error fetching invoices:', error);
    }
  };

  useEffect(() => {
    fetchSubscriptionData();
    fetchInvoices();
  }, []);

  const handleSubscribe = async () => {
    try {
      setLoading(true);
      const response = await api.post('/subscription/create-checkout-session');
      window.location.href = response.data.checkout_url;
    } catch (error) {
      setError('Failed to initiate subscription');
      console.error('Error creating subscription:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleCancelSubscription = async () => {
    try {
      setLoading(true);
      await api.post('/subscription/cancel');
      await fetchSubscriptionData();
    } catch (error) {
      setError('Failed to cancel subscription');
      console.error('Error cancelling subscription:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleReactivate = async () => {
    try {
      setLoading(true);
      await api.post('/subscription/reactivate');
      await fetchSubscriptionData();
      setError(null);
    } catch (error) {
      setError('Failed to reactivate subscription');
      console.error('Error reactivating subscription:', error);
    } finally {
      setLoading(false);
    }
  };

  if (loading && !subscriptionData) {
    return (
      <Box className="flex justify-center p-12">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box className="max-w-4xl mx-auto">
      {error && (
        <Alert
          severity="error"
          className="mb-6"
          variant="outlined"
        >
          {error}
        </Alert>
      )}

      <Box
        sx={{
          p: 1,
          borderBottom: `1px solid ${theme.palette.divider}`,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}
      >
        <Typography variant="h6" fontWeight="bold">
          Your Subscription
        </Typography>
      </Box>

      <PriceCard
        isActive={subscriptionData?.has_subscription}
        onSubscribe={handleSubscribe}
        onCancel={handleCancelSubscription}
        onReactivate={handleReactivate}
        subscriptionData={subscriptionData}
        isLoading={loading}
      />

      {invoices.length > 0 && (
        <Box className="mt-8">
          <BillingHistory invoices={invoices} />
        </Box>
      )}
    </Box>
  );
};

export default BillingSection;