import React, { createContext, useState, useContext, useEffect } from 'react';
import { useKeycloak } from '@react-keycloak/web';
import ApiService from '../services/api';

const SessionContext = createContext();

export const SessionProvider = ({ children }) => {
  const { keycloak, initialized } = useKeycloak();
  const api = ApiService();
  
  const [session, setSession] = useState({
    user: {
      firstName: null,
      lastName: null,
      email: null,
      username: null,
    },
    isAuthenticated: false,
    token: null,
    roles: [],
    isLoading: true,
    error: null,
    userProfile: {
      profileImageUrl: null,
      defaultLanguage: 'en',
      defaultProfile: '0',
      firstName: '',
      lastName: '',
      email: '',
      country: '',
    },
    subscription: {
      status: 'loading', // 'loading' | 'active' | 'inactive' | 'canceling' | 'error'
      currentPeriodEnd: null,
      hasSubscription: false,
      isLoading: true,
      error: null,
      data: null
    }
  });


  // Fonction pour s'assurer que l'utilisateur existe dans la base de données
  const ensureUserExists = async (userInfo) => {
    try {
      // Vérifier si l'utilisateur existe déjà
      const response = await api.get('/users/check', {
        //params: { user_id: keycloak.subject }
      });

      // Si l'utilisateur n'existe pas, le créer
      if (!response.data.exists) {
        await api.post('/users/create');
        
        console.log('New user created in database');
      }
    } catch (error) {
      console.error('Error ensuring user exists:', error);
      throw error;
    }
  };



  // Fonction pour charger le statut de l'abonnement
  const loadSubscriptionStatus = async () => {
    if (!initialized || !keycloak.authenticated) return;

    try {
      setSession(prev => ({
        ...prev,
        subscription: {
          ...prev.subscription,
          isLoading: true,
          error: null
        }
      }));

      const response = await api.get('/subscription/status');
      const subscriptionData = response.data;

      setSession(prev => ({
        ...prev,
        subscription: {
          status: subscriptionData.status || (subscriptionData.has_subscription ? 'active' : 'inactive'),
          currentPeriodEnd: subscriptionData.current_period_end || null,
          hasSubscription: subscriptionData.has_subscription || false,
          isLoading: false,
          error: null,
          data: subscriptionData
        }
      }));
    } catch (error) {
      console.error('Error loading subscription status:', error);
      setSession(prev => ({
        ...prev,
        subscription: {
          ...prev.subscription,
          status: 'error',
          isLoading: false,
          error: 'Failed to load subscription status'
        }
      }));
    }
  };



  const updateProfileImageUrl = (imageUrl) => {
    setSession((prevSession) => ({
      ...prevSession,
      userProfile: {
        ...prevSession.userProfile,
        profileImageUrl: imageUrl,
      },
    }));
  };


  const updateDefaultLanguage = (language) => {
    setSession((prevSession) => ({
      ...prevSession,
      userProfile: {
        ...prevSession.userProfile,
        defaultLanguage: language,
      },
    }));
  };

  const loadUserProfile = async () => {
    try {
      setSession(prev => ({ ...prev, isLoading: true }));
      const response = await api.get('/users/profile');
      
      setSession(prev => ({
        ...prev,
        isLoading: false,
        userProfile: {
          profileImageUrl: response.data.profile_image_url,
          defaultLanguage: response.data.default_language || 'en',
          defaultProfile: response.data.default_profile || '0',
          firstName: response.data.firstname,
          lastName: response.data.lastname,
          email: response.data.email,
          country: response.data.country,
        }
      }));
    } catch (error) {
      console.error('Error loading user profile:', error);
      setSession(prev => ({
        ...prev,
        isLoading: false,
        error: 'Failed to load user profile'
      }));
    }
  };

  useEffect(() => {
    const updateSession = async () => {
      if (initialized && keycloak?.authenticated) {
        try {
          const userProfile = await keycloak.loadUserProfile();
          
          // Extraire les informations du profil utilisateur
          const userInfo = {
            firstName: userProfile.firstName || keycloak.tokenParsed?.given_name,
            lastName: userProfile.lastName || keycloak.tokenParsed?.family_name,
            email: userProfile.email || keycloak.tokenParsed?.email,
            username: userProfile.username || keycloak.tokenParsed?.preferred_username,
          };

          // S'assurer que l'utilisateur existe dans notre base de données
          await ensureUserExists(userInfo);
          
          setSession(prev => ({
            ...prev,
            user: userInfo,
            isAuthenticated: true,
            token: keycloak.token,
            roles: keycloak.realmAccess?.roles || [],
          }));

          // Charger les données additionnelles du profil et l'abonnement en parallèle
          await Promise.all([
            loadUserProfile(),
            loadSubscriptionStatus()
          ]);

        } catch (error) {
          console.error('Error loading user profile:', error);
          // Fallback sur les informations du token
          const tokenInfo = {
            firstName: keycloak.tokenParsed?.given_name,
            lastName: keycloak.tokenParsed?.family_name,
            email: keycloak.tokenParsed?.email,
            username: keycloak.tokenParsed?.preferred_username,
          };

          setSession(prev => ({
            ...prev,
            user: tokenInfo,
            isAuthenticated: true,
            token: keycloak.token,
            roles: keycloak.realmAccess?.roles || [],
            isLoading: false,
            error: 'Failed to initialize user session properly'
          }));
        }
      } else if (initialized) {
        // Réinitialisation de la session
        setSession(prev => ({
          ...prev,
          user: {
            firstName: null,
            lastName: null,
            email: null,
            username: null,
          },
          isAuthenticated: false,
          token: null,
          roles: [],
          isLoading: false,
          subscription: {
            status: 'inactive',
            currentPeriodEnd: null,
            hasSubscription: false,
            isLoading: false,
            error: null,
            data: null
          },
          userProfile: {
            profileImageUrl: null,
            defaultLanguage: 'en',
            defaultProfile: '0',
            firstName: '',
            lastName: '',
            email: '',
            country: '',
          }
        }));
      }
    };
    updateSession();

    // Configuration du rafraîchissement du token
    const refreshInterval = setInterval(() => {
      if (keycloak && keycloak.authenticated) {
        keycloak.updateToken(60)
          .then(refreshed => {
            if (refreshed) {
              console.log('Token was successfully refreshed');
              setSession(prev => ({
                ...prev,
                token: keycloak.token,
              }));
            }
          })
          .catch(() => {
            console.error('Failed to refresh token');
          });
      }
    }, 60000);

    return () => clearInterval(refreshInterval);
  }, [initialized, keycloak]);


  // Fonction de mise à jour du profil utilisateur
  const updateUserProfile = async (newData) => {
    try {
      await api.put('/users/profile', newData);
      setSession(prev => ({
        ...prev,
        userProfile: {
          ...prev.userProfile,
          ...newData
        }
      }));
      return true;
    } catch (error) {
      console.error('Error updating user profile:', error);
      return false;
    }
  };

  // Fonction de déconnexion
  const logout = () => {
    keycloak.logout();
    setSession({
      user: {
        firstName: null,
        lastName: null,
        email: null,
        username: null,
      },
      isAuthenticated: false,
      token: null,
      roles: [],
      isLoading: false,
      error: null,
      userProfile: {
        profileImageUrl: null,
        defaultLanguage: 'en',
        defaultProfile: '0',
        firstName: '',
        lastName: '',
        email: '',
        country: '',
      }
    });
  };

  return (
    <SessionContext.Provider value={{ 
      session,
      logout,
      updateUserProfile,
      refreshProfile: loadUserProfile,
      updateProfileImageUrl,
      updateDefaultLanguage,
    }}>
      {children}
    </SessionContext.Provider>
  );
};

export const useSession = () => {
  const context = useContext(SessionContext);
  if (!context) {
    throw new Error('useSession must be used within a SessionProvider');
  }
  return context;
};

export default SessionContext;