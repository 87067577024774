import React, { useState, useEffect } from 'react';
import { Box, Card, CardContent, Typography, Button, IconButton, Stack, Chip } from '@mui/material';
import { X as CloseIcon, Zap, Clock, Shield } from 'lucide-react';
import { useKeycloak } from '@react-keycloak/web';
import { useTheme, alpha } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';

const TrialCard = () => {
    const { keycloak } = useKeycloak();
    const theme = useTheme();
    const navigate = useNavigate();
    const [isVisible, setIsVisible] = useState(() => {
        const saved = localStorage.getItem('trialCardVisible');
        return saved !== null ? JSON.parse(saved) : true;
    });

    useEffect(() => {
        localStorage.setItem('trialCardVisible', JSON.stringify(isVisible));
    }, [isVisible]);

    const handleStartTrial = () => {
        navigate('settings?tab=subscription');
    };

    const handleClose = () => {
        setIsVisible(false);
    };

    if (!isVisible) return null;

    return (
        <Box sx={{ p: 2 }}>
            <Card
                variant="outlined"
                sx={{
                    backgroundColor: alpha(theme.palette.primary.main, 0.05),
                    border: `1px solid ${theme.palette.primary.main}`,
                    borderRadius: 2,
                    position: 'relative',
                    overflow: 'hidden'
                }}
            >
                <IconButton
                    size="small"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        top: 8,
                        right: 8,
                        color: 'text.secondary',
                        '&:hover': {
                            backgroundColor: alpha(theme.palette.primary.main, 0.1)
                        }
                    }}
                >
                    <CloseIcon size={16} />
                </IconButton>

                <CardContent>
                    <Stack spacing={2}>
                        <Box>
                            <Chip 
                                icon={<Clock size={14} />}
                                label="5-Day Free Trial" 
                                size="small"
                                color="primary"
                                sx={{ mb: 1 }}
                            />
                            <Typography variant="subtitle1" fontWeight="bold" sx={{ mb: 1 }}>
                                Boost Your Cybersecurity Knowledge with CyberSecNews.AI!
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                Try a 5-day free trial and discover curated news tailored for cybersecurity pros and leaders:
                            </Typography>
                        </Box>

                        <Box component="ul" sx={{ 
                            pl: 2, 
                            m: 0,
                            '& li': { 
                                color: 'text.secondary',
                                fontSize: '0.875rem',
                                mb: 0.5
                            }
                        }}>
                            <li>Instant access to top cybersecurity news sources</li>
                            <li>AI-powered summaries for quick insights</li>
                            <li>Customized content for CISOs and technical experts</li>
                        </Box>

                        <Box>
                            <Button
                                variant="contained"
                                fullWidth
                                onClick={handleStartTrial}
                                startIcon={<Zap size={16} />}
                                sx={{
                                    borderRadius: 1,
                                    textTransform: 'none',
                                    py: 1
                                }}
                            >
                                Start Your Free Trial
                            </Button>
                            
                            <Typography 
                                variant="caption" 
                                color="text.secondary"
                                align="center"
                                sx={{ 
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    gap: 0.5,
                                    mt: 1
                                }}
                            >
                                <Shield size={12} />
                                Then $6.99/month USD after trial
                            </Typography>
                        </Box>
                    </Stack>
                </CardContent>
            </Card>
        </Box>
    );
};

export default TrialCard;