import React, { useState, useEffect } from 'react';
import {
  Box,
  IconButton,
  InputBase,
  Chip,
  Badge,
  Collapse,
  Fade,
  Slide,
  Fab,
  useTheme,
} from '@mui/material';
import { Search, Filter, X, ArrowUp } from 'lucide-react';
import FilterDrawer from './MobileFilterDrawer';

// Constantes pour les options de filtre
const TIME_RANGES = [
  { value: '1', label: 'Today' },
  { value: '2', label: 'Yesterday' },
  { value: '8', label: 'Last 7 days' },
  { value: '31', label: 'Last 30 days' },
  { value: '0', label: 'All Time' },
];

const PROFILES = [
  { value: '0', label: 'All Profiles' },
  { value: '1', label: 'CISO' },
  { value: '2', label: 'Cybersecurity Expert' },
  { value: '3', label: 'Incident Response' },
  { value: '4', label: 'Security Analyst' },
];

const DEFAULT_FILTERS = {
  sortOrder: 'desc',
  searchTerm: '',
  timeRange: '0',
  selectedView: 'unread',
  selectedProfile: '0',
  selectedTags: [],
  selectedImportance: 'All',
  language: 'en'
};

const MobileNewsReaderToolbar = ({
  searchTerm = '',
  onSearchChange = () => {},
  filters = DEFAULT_FILTERS,
  defaultFilters = DEFAULT_FILTERS,
  onFilterChange = () => {},
  onResetFilters = () => {},
}) => {
  const theme = useTheme();
  const [filterDrawerOpen, setFilterDrawerOpen] = useState(false);
  const [showFilters, setShowFilters] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const [showSearchFab, setShowSearchFab] = useState(false);
  const [showSearchBar, setShowSearchBar] = useState(true);

  // Calculer le nombre de filtres actifs
  const activeFiltersCount = React.useMemo(() => {
    let count = 0;
    if (filters.timeRange !== defaultFilters.timeRange) count++;
    if (filters.selectedProfile !== defaultFilters.selectedProfile) count++;
    if (filters.sortOrder !== defaultFilters.sortOrder) count++;
    if (filters.selectedView !== defaultFilters.selectedView) count++;
    return count;
  }, [filters, defaultFilters]);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      setIsScrolled(scrollPosition > 100);
      setShowSearchBar(scrollPosition < 100);
      setShowSearchFab(scrollPosition >= 100);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  // Afficher les filtres actifs sous forme de puces
  const renderActiveFilters = () => {
    const activeFilters = [];

    if (filters.timeRange !== defaultFilters.timeRange) {
      const timeRange = TIME_RANGES.find(r => r.value === filters.timeRange);
      activeFilters.push(
        <Chip
          key="time"
          label={timeRange?.label}
          size="small"
          onDelete={() => onFilterChange({ ...filters, timeRange: defaultFilters.timeRange })}
        />
      );
    }

    if (filters.selectedProfile !== defaultFilters.selectedProfile) {
      const profile = PROFILES.find(p => p.value === filters.selectedProfile);
      activeFilters.push(
        <Chip
          key="profile"
          label={profile?.label}
          size="small"
          onDelete={() => onFilterChange({ ...filters, selectedProfile: defaultFilters.selectedProfile })}
        />
      );
    }

    if (filters.selectedView !== defaultFilters.selectedView) {
      activeFilters.push(
        <Chip
          key="view"
          label={`View: ${filters.selectedView}`}
          size="small"
          onDelete={() => onFilterChange({ ...filters, selectedView: defaultFilters.selectedView })}
        />
      );
    }

    return activeFilters;
  };

  return (
    <>
      <Slide direction="down" in={showSearchBar} mountOnEnter unmountOnExit>
        <Box sx={{
          position: 'sticky',
          top: 56,
          zIndex: 1099,
          backgroundColor: 'background.paper',
          boxShadow: theme.shadows[1],
          mt: -6,
          width: '100%'
        }}>
          {/* Barre de recherche et filtres */}
          <Box sx={{
            display: 'flex',
            alignItems: 'center',
            px: 1,
            mx: 0,
          }}>
            <Box sx={{
              display: 'flex',
              flex: 1,
              alignItems: 'center',
              backgroundColor: theme.palette.action.hover,
              borderRadius: 1,
              px: 1,
              boxShadow: '0 1px 3px rgba(0,0,0,0.1)',
            }}>
              <Search size={18} color={theme.palette.text.secondary} />
              <InputBase
                placeholder="Search articles..."
                value={searchTerm}
                onChange={(e) => onSearchChange(e.target.value)}
                sx={{ ml: 1, flex: 1, fontSize: '1rem' }}
              />
              {searchTerm && (
                <IconButton size="small" onClick={() => onSearchChange('')}>
                  <X size={16} />
                </IconButton>
              )}
            </Box>
            <Badge
              badgeContent={activeFiltersCount}
              color="primary"
            >
              <IconButton
                size="small"
                onClick={() => setFilterDrawerOpen(true)}
                sx={{
                  ml: 1,
                  backgroundColor: activeFiltersCount > 0 ? 'primary.main' : 'transparent',
                  color: activeFiltersCount > 0 ? 'white' : 'text.primary',
                }}
              >
                <Filter size={18} />
              </IconButton>
            </Badge>
          </Box>

          {/* Active Filters */}
          {activeFiltersCount > 0 && (
            <Box sx={{
              display: 'flex',
              gap: 1,
              p: 1,
              overflowX: 'auto',
              '&::-webkit-scrollbar': { display: 'none' },
              scrollbarWidth: 'none'
            }}>
              {renderActiveFilters()}
            </Box>
          )}
        </Box>
      </Slide>

      {/* Floating Action Buttons */}
      <Fade in={showSearchFab}>
        <Box sx={{
          position: 'fixed',
          bottom: 16,
          right: 16,
          zIndex: 1200,
          display: 'flex',
          gap: 1
        }}>
          {activeFiltersCount > 0 && (
            <Fab
              size="small"
              color="primary"
              onClick={() => setFilterDrawerOpen(true)}
            >
              <Badge badgeContent={activeFiltersCount} color="error">
                <Filter size={20} />
              </Badge>
            </Fab>
          )}
          <Fab
            color="primary"
            onClick={() => {
              window.scrollTo({ top: 0, behavior: 'smooth' });
              setShowSearchBar(true);
            }}
          >
            <Search size={20} />
          </Fab>
        </Box>
      </Fade>

      {/* Filter Drawer */}
      <FilterDrawer
        open={filterDrawerOpen}
        onClose={() => setFilterDrawerOpen(false)}
        filters={filters}
        defaultFilters={defaultFilters}
        onFilterChange={onFilterChange}
        onResetFilters={onResetFilters}
      />
    </>
  );
};

export default MobileNewsReaderToolbar;