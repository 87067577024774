

const PROFILES = [
  { value: '0', label: 'All Profiles' },
  { value: '1', label: 'CISO' },
  { value: '2', label: 'Cybersecurity Expert' },
  { value: '3', label: 'Incident Response' },
  { value: '4', label: 'Security Analyst' },
];


export default PROFILES;