import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Chip from '@mui/material/Chip';
import { MessageCircle } from 'lucide-react';
import { SlidersHorizontal } from 'lucide-react';
import { MessagesSquare } from 'lucide-react';
import { BookMarked } from 'lucide-react';
import { Newspaper } from 'lucide-react';
import { LayoutDashboard } from 'lucide-react';
import useMediaQuery from '@mui/material/useMediaQuery';

import MenuIcon from '@mui/icons-material/Menu';
import LogoutRoundedIcon from '@mui/icons-material/LogoutRounded';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import { useTheme as useCustomTheme } from '../contexts/ThemeContext'; // Utilisation du ThemeContext personnalisé
import { useKeycloak } from '@react-keycloak/web';
import cybersecnewslogo from '../assets/cybersecnewslogo.ico';
import TrialCard from '../components/TrialCard';
import ExitConfirmationDialog from '../components/ExitConfirmationDialog';
import ApiService from '../services/api';
import { useAppSettings } from '../contexts/AppSettingsContext';
import { useSession } from '../contexts/SessionContext';
import UserSection from '../components/UserSection';
import { Shield } from 'lucide-react';
import { ShieldCheck } from 'lucide-react';
import { Tooltip } from '@mui/material';
import MessagesDrawer from '../components/MessagesDrawer';
import MobileAppBar from '../components/Mobile/MobileAppBar';

const drawerWidth = 280;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open, isSmallScreen }) => ({
        flexGrow: 1,
        // Ajuster le padding en fonction de la taille d'écran
        padding: {
            xs: theme.spacing(0), // Pas de padding sur mobile
            sm: theme.spacing(3)   // Padding normal sur desktop
        },
        paddingTop: {
            xs: theme.spacing(1), // Petit padding en haut sur mobile
            sm: theme.spacing(3)  // Padding normal sur desktop
        },
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginLeft: isSmallScreen ? 0 : drawerWidth - drawerWidth,
        backgroundColor: theme.palette.background.default, // Utiliser la couleur de fond du thème
        ...(isSmallScreen && {
            marginTop: 56, // height of AppBar on small screens
            [`${theme.breakpoints.up('xs')} and (orientation: landscape)`]: {
                marginTop: 48,
            },
            [theme.breakpoints.up('sm')]: {
                marginTop: 64,
            },
        }),
        // Ajouter un conteneur pour le contenu
        '& > *': {
            maxWidth: '100%',
            margin: '0 auto',
            boxSizing: 'border-box'
        }
    }),
);

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: 'space-between',
}));


// Fonction utilitaire pour construire l'URL complète de l'image
const getImageUrl = (imageUrl) => {
    if (!imageUrl) return '';

    // Si l'URL est déjà complète
    if (imageUrl.startsWith('http')) {
        return imageUrl;
    }

    // Nettoyer l'URL de toute référence à /media/
    const cleanPath = imageUrl.replace(/^\/?(media\/)?/, '');
    return `${process.env.REACT_APP_PROFILE_IMAGES_URL}${cleanPath}`;
};


const Layout = ({ children }) => {
    const { session } = useSession();
    const { keycloak } = useKeycloak();
    const { isDarkMode, toggleTheme } = useCustomTheme(); // Utilisation du contexte personnalisé pour le thème
    const [isExitDialogOpen, setIsExitDialogOpen] = useState(false);
    const isSmallScreen = useMediaQuery('(max-width:600px)');
    const [open, setOpen] = useState(!isSmallScreen);
    const [messagesDrawerOpen, setMessagesDrawerOpen] = useState(false);
    const theme = useTheme();
    const api = ApiService();
    const isAdmin = keycloak.hasResourceRole('Admin');
    const isTester = keycloak.hasResourceRole('Tester');
    const {
        savedArticlesCount,
        setSavedArticlesCount,
        unreadArticlesCount,
        setUnreadArticlesCount,
        unreadMessages,
        setUnreadMessages } = useAppSettings();

    //const {firstname, lastname, email } = session.user;
    // Vérifiez si session.user est défini avant d'accéder aux propriétés
    const firstname = session.user?.firstName || '';
    const lastname = session.user?.lastName || '';
    const email = session.user?.email || '';

    const [subscriptionData, setSubscriptionData] = useState(null);

    const adminMenuItems = isAdmin ? [
        {
            text: 'Admin Dashboard',
            icon: <Shield />,
            path: '/admin',
            badge: null
        }
    ] : [];


    // Effet pour charger le nombre d'articles non-lus
    useEffect(() => {
        const fetchUnreadArticlesCount = async () => {
            try {
                const articlesresponse = await api.get('/articles/count');
                setUnreadArticlesCount(articlesresponse.data.count);
            } catch (error) {
                console.error('Error fetching unread articles count:', error);
                // Optionnellement, gérer l'erreur (par exemple, afficher une notification)
            }
        };

        // Ne faire l'appel que si l'utilisateur est authentifié
        if (keycloak.authenticated) {
            fetchUnreadArticlesCount();
        }
    }, [keycloak.authenticated, unreadArticlesCount]); // Dépendance à l'état d'authentification

    useEffect(() => {
        const profileImageURL = getImageUrl(session.userProfile.profileImageUrl);

    }, [session.userProfile.profileImageUrl]);


    // Effet pour charger le nombre d'articles sauvegardés
    useEffect(() => {
        const fetchSavedArticlesCount = async () => {
            try {
                const savedarticlesresponse = await api.get('/articles/readlater/count');
                setSavedArticlesCount(savedarticlesresponse.data.count);

            } catch (error) {
                console.error('Error fetching saved articles count:', error);
                // Optionnellement, gérer l'erreur (par exemple, afficher une notification)
            }
        };

        // Ne faire l'appel que si l'utilisateur est authentifié
        if (keycloak.authenticated) {
            fetchSavedArticlesCount();
        }
    }, [keycloak.authenticated, savedArticlesCount]); // Dépendance à l'état d'authentification

    useEffect(() => {
        const fetchUnreadMessages = async () => {
            try {
                const response = await api.get('/messages/unread/count');
                setUnreadMessages(response.data.count);
            } catch (error) {
                console.error('Error fetching unread messages count:', error);
            }
        };

        fetchUnreadMessages();
    }, [keycloak.authenticated, unreadMessages]); // Dépendance à l'état d'authentification


    const toggleMessagesDrawer = () => {
        setMessagesDrawerOpen(!messagesDrawerOpen);
    };

    // Effet pour charger le nombre d'articles sauvegardés
    // useEffect(() => {
    //     const fetchProfileImage = async () => {
    //         try {
    //             const savedarticlesresponse = await api.get('/articles/readlater/count');
    //             setSavedArticlesCount(savedarticlesresponse.data.count);

    //         } catch (error) {
    //             console.error('Error fetching saved articles count:', error);
    //             // Optionnellement, gérer l'erreur (par exemple, afficher une notification)
    //         }
    //     };

    //     // Ne faire l'appel que si l'utilisateur est authentifié
    //     if (keycloak.authenticated) {
    //         fetchSavedArticlesCount();
    //     }
    // }, [keycloak.authenticated, savedArticlesCount]);


    // Effet pour charger l'abonnement de l'utilisateur
    useEffect(() => {
        const fetchSubscriptionData = async () => {
            try {
                const response = await api.get('/subscription/status');
                setSubscriptionData(response.data.has_subscription);

            } catch (error) {
                console.error('Error fetching subscription data:', error);
                // Optionnellement, gérer l'erreur (par exemple, afficher une notification)
            }
        };

        // Ne faire l'appel que si l'utilisateur est authentifié
        if (keycloak.authenticated) {
            fetchSubscriptionData();
        }
    }, [subscriptionData]);


    const handleDrawerToggle = () => {
        setOpen(!open);
    };

    const handleExitClick = () => {
        setIsExitDialogOpen(true);
    };

    const handleCloseDialog = () => {
        setIsExitDialogOpen(false);
    };

    const handleConfirmExit = () => {
        // Logique pour quitter l'application
        console.log('Quitter l\'application');
        keycloak.logout({ redirectUri: process.env.REACT_APP_KEYCLOAK_LOGOUT_URL });
        // Implémentez ici la logique pour déconnecter l'utilisateur ou rediriger vers une page de déconnexion
    };

    // Handler explicite pour le toggle
    const handleMessagesClick = (event) => {
        event.preventDefault(); // Empêcher la navigation
        setMessagesDrawerOpen(!messagesDrawerOpen);
    };

    const handleLogout = () => {
        keycloak.logout({ redirectUri: process.env.REACT_APP_KEYCLOAK_LOGOUT_URL });
    };

    // Menu items at the top of the sidebar
    const menuItems = [
        /* { text: 'Home', icon: <HomeRoundedIcon /> }, */
        { text: 'Dashboard', icon: <LayoutDashboard />, path: '/dashboard' },
        {
            text: 'Newsfeed',
            icon: <Newspaper />,
            path: '/newsfeed',
            badge: unreadArticlesCount > 0 ? unreadArticlesCount : null // N'afficher le badge que s'il y a des articles
        },
        {
            text: 'Saved',
            icon: <BookMarked />,
            path: '/newsfeed',
            badge: savedArticlesCount > 0 ? savedArticlesCount : null // N'afficher le badge que s'il y a des articles
        },
        {
            text: 'Messages',
            icon: <MessagesSquare />,
            badge: unreadMessages > 0 ? unreadMessages : null,
            action: handleMessagesClick, // Utiliser le handler explicite
            preventNavigate: true // Flag pour identifier les items qui ne doivent pas naviguer
        }

    ];

    const allMenuItems = [...menuItems, ...adminMenuItems];

    // Menu items at the bottom of the sidebar
    const menuItemsBottom = [
        { text: 'Support', icon: <MessageCircle />, path: '/support' },
        { text: 'Settings', icon: <SlidersHorizontal />, path: '/settings' },
    ];

    const profileImageURL = getImageUrl(session.userProfile.profileImageUrl);
    const drawer = (
        <>
            <DrawerHeader>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <IconButton size="small" sx={{ mr: 0.1 }}>
                        {/* <BrightnessAutoRoundedIcon /> */}
                        <img src={cybersecnewslogo} alt="Cybersec News" width="25" height="25" />
                    </IconButton>
                    <Typography level="title-lg">CyberSecNews.AI</Typography>
                    <Box sx={{ ml: 5, mt: 0.8 }}>
                        {isAdmin && (
                            <Tooltip title="Administrator" placement="right">
                                <Shield
                                    size={24}
                                    color={theme.palette.error.main}
                                />
                            </Tooltip>
                        )}
                        {isTester && (
                            <Tooltip title="You have Tester Role" placement="right">
                                <ShieldCheck
                                    size={24}
                                    color={theme.palette.primary.main}
                                />
                            </Tooltip>
                        )}
                    </Box>
                </Box>
                <IconButton onClick={toggleTheme}>
                    {isDarkMode ? <Brightness7Icon /> : <Brightness4Icon />}
                </IconButton>
            </DrawerHeader>
            <List>
                {allMenuItems.map((item) => (
                    <ListItem key={item.text} disablePadding>
                        <ListItemButton
                            component={item.path ? Link : 'button'}
                            to={item.path}
                            onClick={item.action}
                        >
                            <ListItemIcon sx={{ minWidth: 30 }}>
                                {React.cloneElement(item.icon, { fontSize: 'small' })}
                            </ListItemIcon>
                            <ListItemText
                                primary={item.text}
                                primaryTypographyProps={{ fontSize: '1 rem' }}
                            />
                            {item.badge && (
                                <Chip
                                    label={item.badge}
                                    color="primary"
                                    size="small"
                                />
                            )}
                        </ListItemButton>
                    </ListItem>
                ))}
            </List>
            {/* <Divider /> */}
            <Box sx={{ flexGrow: 1 }} />
            <List>
                {menuItemsBottom.map((item) => (
                    <ListItem key={item.text} disablePadding>
                        <ListItemButton component={Link} to={item.path}>
                            <ListItemIcon sx={{ minWidth: 30 }}>
                                {React.cloneElement(item.icon, { fontSize: 'small' })} {/* Ajuster fontSize pour réduire la taille des icônes */}
                            </ListItemIcon> {/* Ajuster minWidth pour réduire l'espace */}
                            <ListItemText primary={item.text} />
                        </ListItemButton>
                    </ListItem>
                ))}
            </List>

            {/* Afficher la carte d'essai si l'utilisateur n'est pas administrateur et n'a pas d'abonnement */}
            {!isAdmin && subscriptionData === false ? (
                <TrialCard />
            ) : null}

            <UserSection
                firstname={firstname}
                lastname={lastname}
                email={email}
                profileImageURL={profileImageURL}
                handleExitClick={handleExitClick}
            />
        </>
    );

    return (
        <Box sx={{ display: 'flex' }}>
            {isSmallScreen && (
                <MobileAppBar
                    open={open}
                    drawerWidth={drawerWidth}
                    handleDrawerToggle={handleDrawerToggle}
                    isDarkMode={isDarkMode}
                    toggleTheme={toggleTheme}
                />
            )}
            <Box
                component="nav"
                sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
            >
                {isSmallScreen ? (
                    <Drawer
                        variant="temporary"
                        open={open}
                        onClose={handleDrawerToggle}
                        ModalProps={{
                            keepMounted: true, // Better open performance on mobile.
                        }}
                        sx={{
                            display: { xs: 'block', sm: 'none' },
                            '& .MuiDrawer-paper': {
                                boxSizing: 'border-box',
                                width: drawerWidth,
                                backgroundColor: theme.palette.background.menu, // Utiliser la couleur de fond personnalisée
                                overflowX: 'hidden'
                            },
                        }}
                    >
                        {drawer}
                    </Drawer>
                ) : (
                    <Drawer
                        variant="permanent"
                        sx={{
                            display: { xs: 'none', sm: 'block' },
                            '& .MuiDrawer-paper': {
                                boxSizing: 'border-box',
                                width: drawerWidth,
                                backgroundColor: theme.palette.background.menu, // Utiliser la couleur de fond personnalisée
                                overflowX: 'hidden'
                            },

                        }}
                        open
                    >
                        {drawer}
                    </Drawer>
                )}
            </Box>
            <Main open={open} isSmallScreen={isSmallScreen}>
                {isSmallScreen && <DrawerHeader />}
                {children}
            </Main>
            <MessagesDrawer
                open={messagesDrawerOpen}
                onClose={() => setMessagesDrawerOpen(false)}
            />
            <ExitConfirmationDialog
                open={isExitDialogOpen}
                onClose={handleCloseDialog}
                onConfirm={handleConfirmExit}
            />
        </Box>
    );
};

export default Layout;
