// components/ProtectedRoute.jsx
import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useKeycloak } from '@react-keycloak/web';
import { CircularProgress, Box } from '@mui/material';

const ProtectedRoute = ({ element: Element, requiredRoles = [] }) => {
  const { keycloak, initialized } = useKeycloak();
  const location = useLocation();

  // Afficher un loader pendant l'initialisation de Keycloak
  if (!initialized) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
        <CircularProgress />
      </Box>
    );
  }

  // Rediriger vers la page de connexion si non authentifié
  if (!keycloak.authenticated) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  // Vérifier les rôles si requis
  if (requiredRoles.length > 0) {
    const hasRequiredRoles = requiredRoles.every(role => {
      // Vérifier d'abord les rôles du realm
      if (keycloak.hasRealmRole(role)) {
        return true;
      }
      // Ensuite vérifier les rôles spécifiques au client
      return keycloak.hasResourceRole(role, process.env.REACT_APP_KEYCLOAK_CLIENT_ID);
    });

    if (!hasRequiredRoles) {
      return <Navigate to="/unauthorized" state={{ from: location }} replace />;
    }
  }

  // Rendre le composant si toutes les vérifications sont passées
  return <Element />;
};

export default ProtectedRoute;