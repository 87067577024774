import { useState, useEffect } from 'react';
import {
  Card,
  CardContent,
  Typography,
  Box,
  Drawer,
  Chip,
  useTheme,
  alpha,
  IconButton
} from '@mui/material';
import { 
  X as CloseIcon,
  Clock,
  Info,
  Sparkles,
  Shield
} from 'lucide-react';
import ApiService from '../services/api';

const typeIcons = {
  maintenance: <Clock size={20} />,
  announcement: <Info size={20} />,
  feature: <Sparkles size={20} />,
  security: <Shield size={20} />,
  other: <Info size={20} />
};

const priorityColors = {
  high: 'error',
  medium: 'warning',
  low: 'success'
};

const MessageItem = ({ message, onRead }) => {
  const theme = useTheme();
  const isUnread = !message.is_read;

  return (
    <Box 
      sx={{ 
        position: 'relative',
        mb: 2,
        '&:hover': {
          '& .message-card': {
            borderColor: theme.palette.primary.main,
            transform: 'translateY(-1px)',
            boxShadow: theme.shadows[1]
          }
        }
      }}
      onClick={() => onRead && onRead(message.id)}
    >
      {isUnread && (
        <Box
          sx={{
            width: 8,
            height: 8,
            bgcolor: 'primary.main',
            borderRadius: '50%',
            position: 'absolute',
            top: 12,
            left: -12,
            zIndex: 1,
            boxShadow: `0 0 0 2px ${theme.palette.background.paper}`
          }}
        />
      )}
      
      <Card 
        elevation={0}
        className="message-card"
        sx={{
          border: 1,
          borderColor: 'divider',
          borderRadius: 2,
          bgcolor: isUnread ? alpha(theme.palette.primary.main, 0.05) : 'background.paper',
          transition: theme.transitions.create(['transform', 'border-color', 'box-shadow']),
          cursor: 'pointer'
        }}
      >
        <CardContent>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 1 }}>
            <Box sx={{ color: theme.palette.primary.main }}>
              {typeIcons[message.type]}
            </Box>
            <Typography variant="subtitle1" fontWeight={isUnread ? 600 : 500}>
              {message.title}
            </Typography>
            <Box sx={{ flexGrow: 1 }} />
            <Chip 
              size="small"
              label={message.priority}
              color={priorityColors[message.priority]}
              variant={isUnread ? "filled" : "outlined"}
              sx={{ minWidth: 75, textAlign: 'center' }}
            />
          </Box>

          <Typography 
            variant="body2" 
            color={isUnread ? "text.primary" : "text.secondary"}
            paragraph
            sx={{ ml: 4 }}
          >
            {message.content}
          </Typography>

          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mt: 2, ml: 4 }}>
            <Typography variant="caption" color="text.secondary">
              {new Date(message.start_date).toLocaleDateString()}
            </Typography>
            {message.end_date && (
              <Typography variant="caption" color="text.secondary">
                Expires: {new Date(message.end_date).toLocaleDateString()}
              </Typography>
            )}
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
};

const MessagesDrawer = ({ open, onClose }) => {
  const [messages, setMessages] = useState([]);
  const theme = useTheme();
  const api = ApiService();

  useEffect(() => {
    if (open) {
      fetchMessages();
    }
  }, [open]);

  const fetchMessages = async () => {
    try {
      const response = await api.get('/messages');
      setMessages(response.data.sort((a, b) => {
        // Trier d'abord par non lu, puis par date
        if (a.is_read !== b.is_read) return a.is_read ? 1 : -1;
        return new Date(b.start_date) - new Date(a.start_date);
      }));
    } catch (error) {
      console.error('Error fetching messages:', error);
    }
  };

  const handleRead = async (messageId) => {
    try {
      await api.post(`/messages/${messageId}/read`);
      await fetchMessages();
    } catch (error) {
      console.error('Error marking message as read:', error);
    }
  };

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: {
          width: { xs: '100%', sm: 400 },
          p: 3,
          overflow: 'hidden'
        }
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center', mb: 3 }}>
        <Typography variant="h6" fontWeight="bold">
          Messages
        </Typography>
        <Box sx={{ flexGrow: 1 }} />
        <IconButton onClick={onClose} size="small">
          <CloseIcon />
        </IconButton>
      </Box>

      <Box sx={{ 
        overflowY: 'auto',
        height: 'calc(100vh - 100px)',
        pl: 3,
        pr: 1,
        mr: -1,
        '&::-webkit-scrollbar': {
          width: '6px',
        },
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: theme.palette.divider,
          borderRadius: '3px',
        },
        '&::-webkit-scrollbar-track': {
          backgroundColor: 'transparent',
        }
      }}>
        {messages.map((message) => (
          <MessageItem
            key={message.id}
            message={message}
            onRead={handleRead}
          />
        ))}
        
        {messages.length === 0 && (
          <Box sx={{ 
            height: '100%', 
            display: 'flex', 
            alignItems: 'center', 
            justifyContent: 'center' 
          }}>
            <Typography 
              variant="body2" 
              color="text.secondary"
              align="center"
            >
              No messages to display
            </Typography>
          </Box>
        )}
      </Box>
    </Drawer>
  );
};

export default MessagesDrawer;