import React, { useContext } from 'react';
import { Box, useTheme, useMediaQuery, Typography } from '@mui/material';
import { useAppSettings } from '../contexts/AppSettingsContext';
import CriticalArticlesCard from '../components/dashboard/CriticalArticlesCard';
import HighArticlesCard from '../components/dashboard/HighArticlesCard';
import TotalArticlesCard from '../components/dashboard/TotalArticlesCard';
import RisingKeywordsCard from '../components/dashboard/RisingKeywordsCard';
import WordcloudChart from '../components/dashboard/WordCloud';
import ArticlesbyProfile from '../components/dashboard/ArticlesbyProfile';
import ArticlesbyDomain from '../components/dashboard/ArticlesbyDomain';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import { styled } from '@mui/material/styles';



const StyledFormControl = styled(FormControl)(({ theme }) => ({
  minWidth: 300,
  marginBottom: theme.spacing(2),
}));

const Dashboard = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
  const {selectedPeriod, setSelectedPeriod}  = useAppSettings();  

  
  const handlePeriodChange = (event) => {
    setSelectedPeriod(event.target.value);
  };

  return (
    <Box display="flex" flexDirection="column" gap={2} p={1}>
      <Box>
        <StyledFormControl size={isSmallScreen ? "small" : "medium"}>
          <InputLabel id="time-frame-select-label">Period</InputLabel>
          <Select
            labelId="time-frame-select-label"
            value={selectedPeriod}
            onChange={handlePeriodChange}
            label="Period"
            startAdornment={<CalendarTodayIcon sx={{ mr: 1 }} />}
          >
            <MenuItem value="lastLogin">Last Login</MenuItem>
            <MenuItem value="1">Last Day</MenuItem>
            <MenuItem value="7">Last Week</MenuItem>
            <MenuItem value="30">Last Month</MenuItem>
          </Select>
        </StyledFormControl>
      </Box>
      
      <Box
        display="grid"
        gridTemplateColumns={{
          xs: '1fr',
          sm: 'repeat(2, 1fr)',
          md: 'repeat(3, 1fr)',
          lg: 'repeat(4, 1fr)',
        }}
        gap={2}
      >
        <CriticalArticlesCard selectedPeriod={7} isSmallScreen={isSmallScreen} />
        <HighArticlesCard selectedPeriod={7} isSmallScreen={isSmallScreen} />
        <RisingKeywordsCard selectedPeriod={30} isSmallScreen={isSmallScreen} />
        <TotalArticlesCard selectedPeriod={7} isSmallScreen={isSmallScreen} />
      </Box>
      
      <Box
        display="grid"
        gridTemplateColumns={{
          xs: '1fr',
          md: 'repeat(2, 1fr)',
          lg: 'repeat(3, 1fr)',
        }}
        gap={2}
      >
        <Box gridColumn={{ xs: '1', md: '1 / 3', lg: '1 / 2' }}>
          <WordcloudChart isSmallScreen={isSmallScreen} />
        </Box>
        <ArticlesbyProfile isSmallScreen={isSmallScreen} />
        <ArticlesbyDomain isSmallScreen={isSmallScreen} />
      </Box>
      
      {isSmallScreen && (
        <Typography variant="body2" color="text.secondary" align="center">
          Some charts may be simplified for better mobile viewing.
        </Typography>
      )}
    </Box>
  );
};

export default Dashboard;