// MobileArticleComponent.jsx
import React from 'react';
import {
  Card,
  CardContent,
  Typography,
  IconButton,
  Box,
  Collapse,
  CardActionArea,
  useTheme
} from '@mui/material';
import { Bookmark, ThumbsUp, Share2, ExternalLink } from 'lucide-react';
import { getImportance, getImportanceColor, getImportanceLetter } from '../../variables/importanceOptions';

const MobileArticleComponent = ({
  article,
  onReadLaterToggle,
  onLikeToggle,
  isReadLater,
  isLiked,
  cardColor,
  sx
}) => {
  const [expanded, setExpanded] = React.useState(false);
  const theme = useTheme();

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const now = new Date();
    const diffInHours = Math.floor((now - date) / (1000 * 60 * 60));
    
    if (diffInHours < 24) {
      return `${diffInHours}h ago`;
    }
    return date.toLocaleDateString();
  };

  // Extract domain from URL
  const getDomain = (url) => {
    try {
      const domain = new URL(url).hostname;
      return domain.replace(/^www\./, '');
    } catch {
      return url;
    }
  };

  return (
    <Card
    sx={{
      mb: 1,
      width: '100%',
      maxWidth: '100%',
      boxShadow: 'none',
      border: 'none',
      borderBottom: '1px solid',
      borderColor: 'divider',
      borderRadius: 0,
      backgroundColor: 'transparent',
      mx: 'auto',     // Change to auto
      px: 0,
      position: 'relative', // Ajout important
      left: {
        xs: '-8px',   // Compensation sur mobile
        sm: 0
      },
      width: {
        xs: 'calc(100% + 16px)', // Compensation de la largeur sur mobile
        sm: '100%'
      }
    }}
      elevation={0}
    >
      <CardActionArea 
        onClick={() => setExpanded(!expanded)}
        sx={{ 
          p: 0.5,
          width: '100%',
          '&:hover': {
            backgroundColor: theme.palette.action.hover,
            borderRadius: 1
          }
        }}
      >
        <CardContent sx={{ p: '2px', width: '100%' }}>
          {/* Main container */}
          <Box sx={{ width: '100%', margin: '0 auto' }}>
            {/* Article metadata */}
  <Box sx={{ 
    display: 'flex', 
    alignItems: 'center', 
    gap: 0,
    mb: 0.25,
    flexWrap: 'wrap',
    width: '100%'
  }}>
    <Typography 
      variant="caption" 
      color="text.secondary"
      sx={{ 
        fontSize: '0.75rem',
        fontWeight: 500
      }}
    >
      {article.source}
    </Typography>
    <Box
      sx={{
        width: 16,
        height: 16,
        borderRadius: '50%',
        backgroundColor: cardColor,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: '#fff',
        fontSize: '0.6rem',
        fontWeight: 'bold',
        boxShadow: '0 1px 2px rgba(0,0,0,0.1)',
        ml: 0
      }}
    >
      {getImportanceLetter(article.score)}
    </Box>
    <Typography 
      variant="caption" 
      color="text.secondary"
      sx={{ fontSize: '0.75rem', marginLeft: '5px' }}
    >
      {formatDate(article.published_at)}
    </Typography>
  </Box>

            {/* Title */}
            <Typography 
              variant="subtitle1" 
              sx={{ 
                fontWeight: 600,
                fontSize: '0.95rem',
                lineHeight: 1.3,
                mb: 0.5,
                color: theme.palette.text.primary,
                width: '100%'
              }}
            >
              {article.title}
            </Typography>

            {/* Source URL */}
            <Typography
              variant="caption"
              sx={{
                display: 'block',
                mb: 0.5,
                color: theme.palette.text.secondary,
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                fontSize: '0.75rem',
                width: '100%'
              }}
            >
              {getDomain(article.url)}
            </Typography>

            {/* Action buttons */}
            <Box
    sx={{
      display: 'flex',
      gap: 2.5,
      mb: 0.5,
      width: '100%'
    }}
    onClick={(e) => e.stopPropagation()}
  >
    <IconButton
      size="small"
      onClick={onLikeToggle}
      sx={{ 
        color: isLiked ? 'primary.main' : 'text.secondary',
        p: 0.5
      }}
    >
      <ThumbsUp size={18} />
    </IconButton>
    <IconButton
      size="small"
      onClick={onReadLaterToggle}
      sx={{ 
        color: isReadLater ? 'primary.main' : 'text.secondary',
        p: 0.5
      }}
    >
      <Bookmark size={18} />
    </IconButton>
    <IconButton
      size="small"
      onClick={() => window.open(article.url, '_blank')}
      sx={{ 
        p: 0.5,
        color: 'text.secondary'
      }}
    >
      <ExternalLink size={18} />
    </IconButton>
    <IconButton 
      size="small"
      onClick={() => {
        navigator.share({
          title: article.title,
          text: article.description,
          url: article.url
        }).catch(console.error);
      }}
      sx={{ 
        p: 0.5,
        color: 'text.secondary'
      }}
    >
      <Share2 size={18} />
    </IconButton>
  </Box>

            {/* Tags */}
            <Box sx={{ 
              display: 'flex', 
              gap: 1.5, 
              flexWrap: 'wrap',
              alignItems: 'center',
              width: '100%'
            }}>
              {article.tags?.slice(0, 3).map((tag, index) => (
              <Typography 
                  key={tag}
                variant="caption" 
                sx={{ 
                    color: 'text.secondary',
                  fontSize: '0.75rem',
                    fontWeight: 500,
                    '&:not(:last-child):after': {
                      content: '"•"',
                      marginLeft: 1.5,
                      position: 'absolute'
                    }
                }}
              >
                  {tag}
              </Typography>
              ))}
            </Box>
          </Box>
        </CardContent>
      </CardActionArea>

      {/* Expanded content */}
      <Collapse in={expanded} timeout="auto">
        <CardContent sx={{ 
          py: 1.5,
          px: 1,
          width: '100%'
        }}>
          {/* Summary */}
          <Typography 
            variant="body2" 
            color="text.primary"
            sx={{ 
              fontSize: '0.9rem',
              lineHeight: 1.6,
              mb: 2,
              width: '100%'
            }}
          >
            {article.summary}
          </Typography>

          {/* Main points */}
          {article.main_points && (
            <Box sx={{ mb: 2, width: '100%' }}>
              <Typography
                variant="subtitle2"
                sx={{
                  fontSize: '0.85rem',
                  fontWeight: 600,
                  mb: 1,
                  color: theme.palette.text.primary
                }}
              >
                Main points:
              </Typography>
              <Box component="ul" sx={{ 
                m: 0, 
                pl: 2,
                width: '100%',
                '& li': {
                  fontSize: '0.85rem',
                  color: theme.palette.text.secondary,
                  lineHeight: 1.6,
                  mb: 0.75,
                  '&::marker': {
                    color: theme.palette.primary.main
                  }
                }
              }}>
                {Array.isArray(article.main_points) 
                  ? article.main_points.map((point, index) => (
                      <li key={index}>{point}</li>
                    ))
                  : typeof article.main_points === 'string' 
                    ? article.main_points.split('\n').map((point, index) => (
                        point.trim() && <li key={index}>{point.trim()}</li>
                      ))
                    : null
                }
              </Box>
            </Box>
          )}

          {/* Takeaways */}
          {article.takeaways && (
            <Box sx={{ width: '100%' }}>
              <Typography
                variant="subtitle2"
                sx={{
                  fontSize: '0.85rem',
                  fontWeight: 600,
                  mb: 1,
                  color: theme.palette.text.primary
                }}
              >
                Key takeaways:
              </Typography>
              <Box component="ul" sx={{ 
                m: 0, 
                pl: 2,
                width: '100%',
                '& li': {
                  fontSize: '0.85rem',
                  color: theme.palette.text.secondary,
                  lineHeight: 1.6,
                  mb: 0.75,
                  '&::marker': {
                    color: theme.palette.primary.main
                  }
                }
              }}>
                {Array.isArray(article.takeaways) 
                  ? article.takeaways.map((takeaway, index) => (
                      <li key={index}>{takeaway}</li>
                    ))
                  : typeof article.takeaways === 'string' 
                    ? article.takeaways.split('\n').map((takeaway, index) => (
                        takeaway.trim() && <li key={index}>{takeaway.trim()}</li>
                      ))
                    : null
                }
              </Box>
            </Box>
          )}
        </CardContent>
      </Collapse>
    </Card>
  );
};

export default MobileArticleComponent;
