import { useState, useEffect } from 'react';

const useUserTimezone = () => {
  const [userTimezone, setUserTimezone] = useState('');

  useEffect(() => {
    try {
      const detectedTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      setUserTimezone(detectedTimezone);
    } catch (error) {
      console.error('Error detecting user timezone:', error);
      setUserTimezone('UTC'); // Fallback to UTC
    }
  }, []);

  return userTimezone;
};

export default useUserTimezone;