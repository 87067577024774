import axios from 'axios';
import keycloak from '../components/auth/keycloak';


// Définir les endpoints sans le préfixe /api/v1 car il est déjà dans le baseURL
const API_ENDPOINTS = {
  PROFILE: {
    BASE: '/profile',
    IMAGE: '/profile/image'
  },
  ARTICLES: {
    BASE: '/articles'
  },
  SUBSCRIPTION: {
    BASE: '/subscription',
    STATUS: '/subscription/status'
  }
};

const ApiService = () => {

  // Récupérer l'URL de l'API depuis les variables d'environnement
  const API_URL = process.env.REACT_APP_API_URL;

  // Créer l'instance axios
  const api = axios.create({
    baseURL: API_URL, // Le préfixe /API/v1 est inclus ici
    withCredentials: true
  });

  // Ajouter un intercepteur pour inclure le token d'authentification
  api.interceptors.request.use(config => {
    if (keycloak.authenticated) {
      config.headers.Authorization = `Bearer ${keycloak.token}`;
    }
    return config;
  });

  const apiService = {
    async call(method, endpoint, data = null, params = null, config = {}) {
      try {
        const response = await api({
          method,
          url: endpoint, // L'endpoint n'inclut plus le préfixe /api/v1
          data,
          params,
          ...config
        });
        return response;
      } catch (error) {
        console.error('API call error:', error);
        throw error;
      }
    },

    get(endpoint, params) {
      return this.call('get', endpoint, null, params);
    },

    post(endpoint, data, config = {}) {
      return this.call('post', endpoint, data, null, config);
    },

    put(endpoint, data) {
      return this.call('put', endpoint, data);
    },

    delete(endpoint) {
      return this.call('delete', endpoint);
    },

    profile: {
      async updateImage(formData) {
        const response = await apiService.post(API_ENDPOINTS.PROFILE.IMAGE, formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        });
        return response.data;
      },

      async update(profileData) {
        const response = await apiService.put(API_ENDPOINTS.PROFILE.BASE, profileData);
        return response.data;
      },

      async get() {
        const response = await apiService.get(API_ENDPOINTS.PROFILE.BASE);
        return response.data;
      }
    },

    articles: {
      async getAll(params) {
        const response = await apiService.get(API_ENDPOINTS.ARTICLES.BASE, params);
        return response.data;
      }
    },

    subscription: {
      getStatus() {
        return apiService.get(API_ENDPOINTS.SUBSCRIPTION.STATUS)
          .then(response => response.data);
      }
    }
  };

  return apiService;
};

export default ApiService;