import React, { createContext, useState, useContext } from 'react';
import { LAST_LOGIN_KEY, TTL_IN_MS, READING_MODE_KEY, LANGUAGE_KEY, DEFAULT_PROFILE_KEY } from '../variables/uservariables';

const AppSettingsContext = createContext();

export const AppSettingsProvider = ({ children }) => {
  const [sortOrder, setSortOrder] = useState('desc');
  const [groupOption, setGroupOption] = useState('date');
  const [searchTerm, setSearchTerm] = useState('');
  const [unreadArticles, setUnreadArticles] = useState(0);
  const [unreadMessages, setUnreadMessages] = useState(0);
  const [currentCursor, setCurrentCursor] = useState(null);
  const [language, setLanguage] = useState('en');
  const [colorFilter, setColorFilter] = useState(['']);
  const [selectedProfile, setSelectedProfile] = useState(null);
  const [selectedPeriod, setSelectedPeriod] = useState('7');
  const [selectedView, setSelectedView] = useState('unread');
  const [daysBack, setDaysBack] = useState(null);
  const [defaultProfile, setDefaultProfile] = useState(() => {
    const savedProfile = localStorage.getItem(DEFAULT_PROFILE_KEY);
    return savedProfile !== null ? parseInt(savedProfile, 10) : 0;
  });
  const [savedArticlesCount, setSavedArticlesCount] = useState(0);
  const [unreadArticlesCount, setUnreadArticlesCount] = useState(0);
  

  const REACT_APP_PROFILE_IMAGES_URL = process.env.REACT_APP_PROFILE_IMAGES_URL;



  return (
    <AppSettingsContext.Provider 
      value={{
        REACT_APP_PROFILE_IMAGES_URL,
        sortOrder, setSortOrder,
        groupOption, setGroupOption,
        searchTerm, setSearchTerm,
        unreadArticles, setUnreadArticles,
        currentCursor, setCurrentCursor,
        language, setLanguage,
        colorFilter, setColorFilter,
        selectedProfile, setSelectedProfile,
        selectedPeriod, setSelectedPeriod,
        selectedView, setSelectedView,
        daysBack, setDaysBack,
        defaultProfile, setDefaultProfile,
        savedArticlesCount, setSavedArticlesCount,
        unreadArticlesCount, setUnreadArticlesCount,
        unreadMessages, setUnreadMessages,
      }}
    >
      {children}
    </AppSettingsContext.Provider>
  );
};

export const useAppSettings = () => useContext(AppSettingsContext);
