import React, { useState } from 'react';
import {
  Box,
  Paper,
  Typography,
  TextField,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Alert,
  Stack,
  InputAdornment,
  useTheme,
  alpha,
  Collapse
} from '@mui/material';
import {
  MessageCircle,
  AlertTriangle,
  Lightbulb,
  Send,
  Bug,
  HelpCircle,
  CheckCircle
} from 'lucide-react';
import ApiService from '../services/api';

const TICKET_TYPES = [
  { value: 'bug', label: 'Bug Report', icon: Bug, color: 'error.main' },
  { value: 'feature', label: 'Feature Request', icon: Lightbulb, color: 'warning.main' },
  { value: 'support', label: 'Support Request', icon: HelpCircle, color: 'info.main' },
];

const SupportSection = () => {
  const [formData, setFormData] = useState({
    type: '',
    title: '',
    description: '',
  });
  const [submitStatus, setSubmitStatus] = useState({ type: '', message: '' });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const theme = useTheme();
  const api = ApiService();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
    setSubmitStatus({ type: '', message: '' });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!formData.type || !formData.title || !formData.description) {
      setSubmitStatus({
        type: 'error',
        message: 'Please fill in all fields'
      });
      return;
    }

    setIsSubmitting(true);
    try {
      const payload = {
        type: formData.type.toLowerCase(), // Conversion en minuscules
        title: formData.title,
        description: formData.description
      };

      await api.post('/support/tickets', payload);
      setSubmitStatus({
        type: 'success',
        message: 'Your ticket has been submitted successfully. We will review it shortly.'
      });
      setFormData({ type: '', title: '', description: '' });
    } catch (error) {
      const errorMessage = error.response?.data?.detail || 'Failed to submit ticket. Please try again.';
      setSubmitStatus({
        type: 'error',
        message: errorMessage
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  const getSelectedTypeIcon = () => {
    const selectedType = TICKET_TYPES.find(type => type.value === formData.type);
    return selectedType?.icon || MessageCircle;
  };

  return (
    <Box sx={{ maxWidth: 800, mx: 'auto', p: 2 }}>
      <Paper
        elevation={0}
        sx={{
          border: `1px solid ${theme.palette.divider}`,
          borderRadius: 3,
          overflow: 'hidden'
        }}
      >
        <Box
          sx={{
            p: 3,
            borderBottom: `1px solid ${theme.palette.divider}`,
            bgcolor: 'background.default'
          }}
        >
          <Typography variant="h5" sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <MessageCircle size={24} />
            Support & Feedback
          </Typography>
          <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
            We're here to help! Use the form below to submit your request,
            report a bug, or suggest a new feature.
          </Typography>
        </Box>

        <Box component="form" onSubmit={handleSubmit} sx={{ p: 3 }}>
          <Stack spacing={3}>
            <Collapse in={!!submitStatus.message}>
              <Alert
                severity={submitStatus.type}
                sx={{ mb: 2 }}
                action={
                  submitStatus.type === 'success' && (
                    <CheckCircle size={20} />
                  )
                }
              >
                {typeof submitStatus.message === 'string' ?
                  submitStatus.message :
                  'An error occurred. Please try again.'}
              </Alert>
            </Collapse>

            <FormControl fullWidth>
              <InputLabel>Request Type</InputLabel>
              <Select
                name="type"
                value={formData.type}
                onChange={handleInputChange}
                label="Request Type"
              >
                {TICKET_TYPES.map(type => (
                  <MenuItem key={type.value} value={type.value}>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                      <type.icon size={18} color={theme.palette[type.color]} />
                      {type.label}
                    </Box>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <TextField
              fullWidth
              label="Title"
              name="title"
              value={formData.title}
              onChange={handleInputChange}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    {React.createElement(getSelectedTypeIcon(), {
                      size: 18,
                      color: theme.palette.text.secondary
                    })}
                  </InputAdornment>
                ),
              }}
            />

            <TextField
              fullWidth
              label="Description"
              name="description"
              value={formData.description}
              onChange={handleInputChange}
              multiline
              rows={6}
              placeholder="Please provide as much detail as possible..."
              helperText={`${formData.description.length}/2000 characters`}
              inputProps={{ maxLength: 2000 }}
            />
          </Stack>

          <Box sx={{
            mt: 4,
            p: 3,
            mx: -3,
            mb: -3,
            bgcolor: alpha(theme.palette.primary.main, 0.02),
            borderTop: `1px solid ${theme.palette.divider}`,
            display: 'flex',
            justifyContent: 'flex-end'
          }}>
            <Button
              type="submit"
              variant="contained"
              disabled={isSubmitting}
              startIcon={<Send size={18} />}
              sx={{ borderRadius: 2, px: 3 }}
            >
              Submit Request
            </Button>
          </Box>
        </Box>
      </Paper>
    </Box>
  );
};

export default SupportSection;