import React, { useState } from 'react';
import {
  Box,
  Drawer,
  IconButton,
  Typography,
  Chip,
  Divider,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Select,
  MenuItem,
  Button,
  useTheme,
} from '@mui/material';
import { X, RefreshCw } from 'lucide-react';

// Options de filtre constants
const TIME_RANGES = [
  { value: '1', label: 'Today' },
  { value: '2', label: 'Yesterday' },
  { value: '8', label: 'Last 7 days' },
  { value: '31', label: 'Last 30 days' },
  { value: '0', label: 'All Time' },
];

const PROFILES = [
  { value: '0', label: 'All Profiles' },
  { value: '1', label: 'CISO' },
  { value: '2', label: 'Cybersecurity Expert' },
  { value: '3', label: 'Incident Response' },
  { value: '4', label: 'Security Analyst' },
];

const FilterDrawer = ({
  open,
  onClose,
  filters,
  defaultFilters,
  onFilterChange,
  onResetFilters,
}) => {
  const theme = useTheme();
  const [localFilters, setLocalFilters] = useState(filters);

  // Handler pour mettre à jour les filtres locaux
  const handleFilterChange = (key, value) => {
    setLocalFilters(prev => ({ ...prev, [key]: value }));
  };

  // Handler pour appliquer les filtres
  const handleApplyFilters = () => {
    onFilterChange(localFilters);
    onClose();
  };

  // Handler pour réinitialiser les filtres
  const handleReset = () => {
    setLocalFilters(defaultFilters);
    onResetFilters();
    onClose();
  };

  // Vérifier si des filtres sont modifiés
  const hasChanges = JSON.stringify(localFilters) !== JSON.stringify(filters);

  return (
    <Drawer
      anchor="bottom"
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: {
          borderTopLeftRadius: 16,
          borderTopRightRadius: 16,
          maxHeight: '90vh',
        }
      }}
    >
      {/* Header */}
      <Box sx={{ 
        p: 2, 
        display: 'flex', 
        justifyContent: 'space-between', 
        alignItems: 'center',
        borderBottom: `1px solid ${theme.palette.divider}`
      }}>
        <Typography variant="h6">Filters</Typography>
        <IconButton onClick={onClose} size="small">
          <X size={20} />
        </IconButton>
      </Box>

      {/* Content */}
      <Box sx={{ 
        p: 2,
        overflowY: 'auto',
        display: 'flex',
        flexDirection: 'column',
        gap: 3
      }}>
        {/* View Selection */}
        <FormControl>
          <FormLabel>View</FormLabel>
          <RadioGroup
            value={localFilters.selectedView}
            onChange={(e) => handleFilterChange('selectedView', e.target.value)}
          >
            <FormControlLabel value="unread" control={<Radio />} label="Unread" />
            <FormControlLabel value="all" control={<Radio />} label="All Articles" />
            <FormControlLabel value="saved" control={<Radio />} label="Saved" />
          </RadioGroup>
        </FormControl>

        <Divider />

        {/* Time Range */}
        <FormControl>
          <FormLabel>Time Range</FormLabel>
          <RadioGroup
            value={localFilters.timeRange}
            onChange={(e) => handleFilterChange('timeRange', e.target.value)}
          >
            {TIME_RANGES.map(range => (
              <FormControlLabel
                key={range.value}
                value={range.value}
                control={<Radio />}
                label={range.label}
              />
            ))}
          </RadioGroup>
        </FormControl>

        <Divider />

        {/* Sort Order */}
        <FormControl>
          <FormLabel>Sort Order</FormLabel>
          <RadioGroup
            value={localFilters.sortOrder}
            onChange={(e) => handleFilterChange('sortOrder', e.target.value)}
          >
            <FormControlLabel value="desc" control={<Radio />} label="Newest first" />
            <FormControlLabel value="asc" control={<Radio />} label="Oldest first" />
          </RadioGroup>
        </FormControl>

        <Divider />

        {/* Profile Selection */}
        <FormControl>
          <FormLabel>Profile</FormLabel>
          <Select
            value={localFilters.selectedProfile}
            onChange={(e) => handleFilterChange('selectedProfile', e.target.value)}
            size="small"
            sx={{ mt: 1 }}
          >
            {PROFILES.map(profile => (
              <MenuItem key={profile.value} value={profile.value}>
                {profile.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>

      {/* Action Buttons */}
      <Box sx={{ 
        p: 2, 
        borderTop: `1px solid ${theme.palette.divider}`,
        display: 'flex',
        gap: 2
      }}>
        <Button
          variant="outlined"
          fullWidth
          onClick={handleReset}
          startIcon={<RefreshCw size={20} />}
        >
          Reset
        </Button>
        <Button
          variant="contained"
          fullWidth
          onClick={handleApplyFilters}
          disabled={!hasChanges}
        >
          Apply Filters
        </Button>
      </Box>
    </Drawer>
  );
};

export default FilterDrawer;