import React, { useState, useEffect, useContext } from 'react';
import { Box, Card, CardContent, Skeleton } from '@mui/material';
import { useAppSettings } from '../../contexts/AppSettingsContext';
import { TrendingUp } from '@mui/icons-material';
import MetricCard from './MetricCard'; // Ensure this path is correct
import ApiService from '../../services/api';
//import { GlobalContext } from '../../GlobalContext';
//import { getItemWithExpiry } from '../../UserContext';
import ErrorMessage from '../ErrorMessage';

// const fetchRisingKeywords = async () => {
//   // This function would call your API endpoint
//   // For now, we'll use mock data
//   return [
//     { text: "Cybersecurity", growth_rate: "450.00", recent_count: 100, previous_count: 20 },
//     // { text: "AI", growth_rate: "325.50", recent_count: 85, previous_count: 20 },
//     // { text: "Cloud Computing", growth_rate: "200.75", recent_count: 60, previous_count: 20 }
//   ];
// };


const RisingKeywordsCard = () => {
  const [keywords, setKeywords] = useState([]);
  const [loading, setLoading] = useState(true);
  const api = ApiService();
  const lastLogin = localStorage.getItem('lastLogin');
  const [error, setError] = useState(null);
  const {selectedPeriod,setSelectedPeriod}  = useAppSettings(); 
  useEffect(() => {
    const getKeywords = async () => {
      try {
        setLoading(true);
        const params = isNaN(selectedPeriod) 
          ? { last_login: lastLogin } 
          : { days: selectedPeriod,
            comparison_days: selectedPeriod
           };
          params.limit = 1; // limit to 1 keyword

        const response = await api.get('/stats/rising_keywords', params );
        setKeywords(response.data); 
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    getKeywords();
  }, [selectedPeriod, lastLogin]);

  if (loading) {
    return (
      <Card sx={{ minWidth: 400, maxWidth: 600, boxShadow: 3 }}>
        <CardContent>
          <Skeleton variant="text" width="60%" />
          <Skeleton variant="rectangular" height={150} />
          <Skeleton variant="text" width="80%" />
        </CardContent>
      </Card>
    );
  }

  if (error) {
    return <ErrorMessage message={error.message} />;
  }

  return (
    <Box>
      {keywords.map((keyword, index) => (
        <MetricCard
          key={index}
          title={index === 0 ? "TOP RISING KEYWORD" : `RISING KEYWORD #${index + 1}`}
          value={keyword.text}
          change={parseFloat(keyword.growth_rate)}
          comparisonText={isNaN(selectedPeriod) ? "Since last login" : `Last ${selectedPeriod} days`}
          icon={TrendingUp}
          iconColor="primary"
          currentMonthArticles={keyword.recent_count}
          previousMonthArticles={keyword.previous_count}
        />
      ))}
    </Box>
  );
};

export default RisingKeywordsCard;