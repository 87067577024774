import React, { useState, useEffect } from 'react';
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  IconButton,
  Typography,
  Chip,
  TextField,
  InputAdornment,
  Stack,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  alpha,
  useTheme
} from '@mui/material';
import {
  Search,
  Clock,
  Info,
  Sparkles,
  Shield,
  Trash2,
  Edit
} from 'lucide-react';
import ApiService from '../../services/api';

// Type icons mapping
const TYPE_ICONS = {
  maintenance: Clock,
  announcement: Info,
  feature: Sparkles,
  security: Shield
};

// Priority colors mapping
const PRIORITY_COLORS = {
  high: 'error',
  medium: 'warning',
  low: 'success'
};

const MessagesList = () => {
  const theme = useTheme();
  const [messages, setMessages] = useState({ items: [], total: 0, page: 1, per_page: 10 });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchTerm, setSearchTerm] = useState('');
  const [typeFilter, setTypeFilter] = useState('all');
  const [sortOrder, setSortOrder] = useState('desc');
  const api = ApiService();

  const [selectedMessage, setSelectedMessage] = useState(null);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

  useEffect(() => {
    fetchMessages();
  }, [page, rowsPerPage, sortOrder]);

  const fetchMessages = async () => {
    try {
      setLoading(true);
      const response = await api.get('/admin/messages/all', {
        params: {
          page: page + 1,  // page est 0-based dans MUI, mais 1-based dans l'API
          per_page: rowsPerPage,
          sort_by: 'created_at', // ajout du champ de tri
          sort_order: sortOrder,
          message_type: typeFilter !== 'all' ? typeFilter : undefined,
          search: searchTerm || undefined
        }
      });
      setMessages(response.data);
      setError(null);
    } catch (err) {
      setError('Failed to load messages');
      console.error('Error fetching messages:', err);
    } finally {
      setLoading(false);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
    setPage(0);
  };

  const handleTypeFilter = (event) => {
    setTypeFilter(event.target.value);
    setPage(0);
  };

  const formatDate = (dateString) => {
    return new Date(dateString).toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    });
  };

  return (
    <Paper
      elevation={0}
      sx={{
        border: `1px solid ${theme.palette.divider}`,
        borderRadius: 3,
        overflow: 'hidden'
      }}
    >
      {/* Search and Filter Bar */}
      <Box
        sx={{
          p: 2,
          borderBottom: `1px solid ${theme.palette.divider}`,
          bgcolor: alpha(theme.palette.primary.main, 0.02),
          display: 'flex',
          gap: 2,
          flexWrap: 'wrap'
        }}
      >
        <TextField
          placeholder="Search messages..."
          value={searchTerm}
          onChange={handleSearch}
          size="small"
          sx={{ flexGrow: 1, minWidth: 200 }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search size={18} />
              </InputAdornment>
            )
          }}
        />

        <FormControl size="small" sx={{ minWidth: 150 }}>
          <InputLabel>Type</InputLabel>
          <Select
            value={typeFilter}
            onChange={handleTypeFilter}
            label="Type"
          >
            <MenuItem value="all">All Types</MenuItem>
            <MenuItem value="maintenance">Maintenance</MenuItem>
            <MenuItem value="announcement">Announcement</MenuItem>
            <MenuItem value="feature">Feature</MenuItem>
            <MenuItem value="security">Security</MenuItem>
          </Select>
        </FormControl>
      </Box>

      {/* Messages Table */}
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Type</TableCell>
              <TableCell>Title</TableCell>
              <TableCell>Priority</TableCell>
              <TableCell>Start Date</TableCell>
              <TableCell>End Date</TableCell>
              <TableCell>Status</TableCell>
              <TableCell align="right">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {messages.items.map((message) => {
              const TypeIcon = TYPE_ICONS[message.type];
              return (
                <TableRow key={message.id} hover>
                  <TableCell>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                      {TypeIcon && <TypeIcon size={18} />}
                      <Typography variant="body2" sx={{ textTransform: 'capitalize' }}>
                        {message.type}
                      </Typography>
                    </Box>
                  </TableCell>
                  <TableCell>
                    <Typography variant="body2" noWrap sx={{ maxWidth: 300 }}>
                      {message.title}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Chip
                      size="small"
                      label={message.priority}
                      color={PRIORITY_COLORS[message.priority]}
                      variant="outlined"
                    />
                  </TableCell>
                  <TableCell>
                    <Typography variant="body2">
                      {formatDate(message.start_date)}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="body2">
                      {message.end_date ? formatDate(message.end_date) : '-'}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Chip
                      size="small"
                      label={message.is_active ? 'Active' : 'Inactive'}
                      color={message.is_active ? 'success' : 'default'}
                      variant="outlined"
                    />
                  </TableCell>
                  <TableCell align="right">
                    <Stack direction="row" spacing={1} justifyContent="flex-end">
                      <IconButton
                        size="small"
                        onClick={() => {
                          setSelectedMessage(message);
                          setEditDialogOpen(true);
                        }}
                      >
                        <Edit size={16} />
                      </IconButton>
                      <IconButton
                        size="small"
                        color="error"
                        onClick={() => {
                          setSelectedMessage(message);
                          setDeleteDialogOpen(true);
                        }}
                      >
                        <Trash2 size={16} />
                      </IconButton>
                    </Stack>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>

      {error && (
        <Box sx={{ p: 2, textAlign: 'center' }}>
          <Typography color="error">{error}</Typography>
        </Box>
      )}

      <TablePagination
        component="div"
        count={messages.total}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        rowsPerPageOptions={[5, 10, 25, 50]}
      />
    </Paper>
  );
};

export default MessagesList;