import React, { useEffect } from 'react';
import { Box, Typography, Paper, Button, Stack, Divider, useTheme, alpha } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { 
  CheckCircle, 
  Newspaper,
  Sparkles,
  Search,
  Share2,
  ArrowRight,
  Mail
} from 'lucide-react';

const SubscriptionSuccessPage = () => {
  const theme = useTheme();
  const navigate = useNavigate();

  // Scroll to top on mount
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const Feature = ({ icon: Icon, title, description }) => (
    <Box sx={{ display: 'flex', gap: 2, alignItems: 'flex-start' }}>
      <Box 
        sx={{ 
          p: 1,
          borderRadius: 1,
          bgcolor: alpha(theme.palette.primary.main, 0.1),
          color: 'primary.main',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <Icon size={20} />
      </Box>
      <Box>
        <Typography variant="subtitle2" fontWeight="medium">
          {title}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {description}
        </Typography>
      </Box>
    </Box>
  );

  return (
    <Box 
      sx={{ 
        minHeight: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        py: 4,
        px: 2,
        bgcolor: 'background.default'
      }}
    >
      <Paper 
        elevation={0}
        sx={{ 
          maxWidth: 600,
          width: '100%',
          p: 4,
          textAlign: 'center',
          border: `1px solid ${theme.palette.divider}`,
          borderRadius: 3
        }}
      >
        <Stack spacing={4} alignItems="center">
          {/* Success Header */}
          <Box>
            <Box 
              sx={{ 
                width: 64,
                height: 64,
                borderRadius: '50%',
                bgcolor: alpha(theme.palette.success.main, 0.1),
                color: 'success.main',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                margin: '0 auto',
                mb: 2
              }}
            >
              <CheckCircle size={32} />
            </Box>
            <Typography variant="h4" fontWeight="bold" gutterBottom>
              Welcome to CyberSecNews.AI Premium!
            </Typography>
            <Typography variant="body1" color="text.secondary">
            Congratulations! Your subscription is active. Discover exclusive features designed to keep you informed and save you time.
            </Typography>
          </Box>

          <Divider flexItem />

          {/* Features Section */}
          <Stack spacing={3} alignItems="stretch" width="100%">
            <Typography variant="h6">Premium Benefits Included:</Typography>
            
            <Feature 
              icon={Newspaper}
              title="Unlimited Cybersecurity News"
              description="Get unrestricted access to the latest in cybersecurity insights, news, and analysis."
            />
            
            <Feature 
              icon={Sparkles}
              title="AI-Powered Summaries"
              description="Quickly grasp essential insights with AI-generated summaries of key articles."
            />
            
            <Feature 
              icon={Search}
              title="Advanced Search & Filters"
              description="Easily find the news that matters most with enhanced search and filtering options."
            />
            
            <Feature 
              icon={Share2}
              title="Collaborative Sharing"
              description="Seamlessly share important news with your team to keep everyone informed."
            />
          </Stack>

          {/* Notification */}
          <Box 
            sx={{
              p: 2,
              bgcolor: alpha(theme.palette.info.main, 0.1),
              borderRadius: 2,
              width: '100%'
            }}
          >
            <Stack direction="row" spacing={1} alignItems="center">
              <Mail size={20} color={theme.palette.info.main} />
              <Typography variant="body2" color="text.secondary">
                A confirmation email has been sent with details of your subscription.
              </Typography>
            </Stack>
          </Box>

          {/* Action Buttons */}
          <Stack spacing={2} direction="row" sx={{ mt: 2 }}>
            <Button
              variant="outlined"
              onClick={() => navigate('/settings')}
              sx={{ borderRadius: 2 }}
            >
              View Subscription Details
            </Button>
            <Button
              variant="contained"
              onClick={() => navigate('/newsfeed')}
              endIcon={<ArrowRight size={16} />}
              sx={{ borderRadius: 2 }}
            >
              Explore Newsfeed
            </Button>
          </Stack>
        </Stack>
      </Paper>
    </Box>
  );
};

export default SubscriptionSuccessPage;