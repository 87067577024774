const importanceOptions = [
  { value: 'critical', label: 'Critical', color: '#FF0000' }, // Rouge
  { value: 'high', label: 'High', color: 'orange' }, // Orange
  { value: 'medium', label: 'Medium', color: 'yellow' }, // Yellow
  { value: 'low', label: 'Low', color: '#008000' }, // Vert
];

const importanceRanges = {
  critical: { min: 85, max: 100, letter: 'C' },
  high: { min: 75, max: 84, letter: 'H' },
  medium: { min: 61, max: 74, letter: 'M' },
  low: { min: 0, max: 60, letter: 'L' },
};

const getImportance = (value) => {
  for (const [importance, range] of Object.entries(importanceRanges)) {
    if (range.min <= value && value <= range.max) {
      return importance;
    }
  }
  return null;
};

const getImportanceLetter = (value) => {
  for (const [importance, range, letter] of Object.entries(importanceRanges)) {
    if (range.min <= value && value <= range.max) {
      return range.letter;
    }
  }
  return null;
};


const colors = {
  critical: "#C70039",
  high: "#FF5E11",
  medium: "#E7B002",
  low: "#008000",
  total: "#3386ff"
};



export { importanceOptions, importanceRanges, getImportance, getImportanceLetter, colors};