import React, { useState } from 'react';
import {
  Box,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Alert,
  Stack,
  Paper,
} from '@mui/material';
import {
  CreditCard,
  Zap,
  Shield,
  Bell,
  AlertCircle,
  Calendar,
  RefreshCw
} from 'lucide-react';
import { alpha, useTheme } from '@mui/material/styles'; // Importer alpha depuis @mui/material/styles
import PlanFeatures from './PlanFeatures';

const PlanFeature = ({ icon: Icon, text }) => (
  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1.5 }}>
    <Icon size={20} color="primary" />
    <Typography variant="body2">{text}</Typography>
  </Box>
);

const SubscriptionDialog = ({ open, onClose, onConfirm, title, message, confirmText, confirmColor = "error" }) => (
  <Dialog open={open} onClose={onClose}>
    <DialogTitle>{title}</DialogTitle>
    <DialogContent>
      <Typography>{message}</Typography>
    </DialogContent>
    <DialogActions>
      <Button onClick={onClose}>Cancel</Button>
      <Button
        onClick={onConfirm}
        color={confirmColor}
        variant="contained"
      >
        {confirmText}
      </Button>
    </DialogActions>
  </Dialog>
);

const PriceCard = ({
  isActive,
  onSubscribe,
  onCancel,
  onReactivate,
  subscriptionData,
  isLoading
}) => {
  const theme = useTheme();
  const [dialogOpen, setDialogOpen] = useState(false);

  const formatDate = (dateString) => {
    if (!dateString) return '';
    return new Date(dateString).toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    });
  };

  const renderSubscriptionStatus = () => {
    if (subscriptionData?.status === 'canceling') {
      return (
        <Stack spacing={2} sx={{ width: '100%' }}>
          <Alert
            severity="warning"
            variant="outlined"
            action={
              <Button
                color="primary"
                size="small"
                startIcon={<RefreshCw size={16} />}
                onClick={onReactivate}
                disabled={isLoading}
              >
                Reactivate
              </Button>
            }
            sx={{
              '& .MuiAlert-message': {
                flex: 1
              },
              '& .MuiAlert-action': {
                alignItems: 'center'
              }
            }}
          >
            Your subscription will end on {formatDate(subscriptionData?.current_period_end)}
          </Alert>
          <Typography
            variant="body2"
            color="text.secondary"
            align="center"
            sx={{ fontStyle: 'italic' }}
          >
            Reactivate to keep your premium access
          </Typography>
        </Stack>
      );
    }

    return (
      <Button
        variant="outlined"
        color="error"
        onClick={() => setDialogOpen(true)}
        startIcon={<AlertCircle size={18} />}
        disabled={isLoading}
        fullWidth
        sx={{ mt: 2 }}
      >
        Cancel Subscription
      </Button>
    );
  };

  return (
    <Paper
      elevation={0}
      sx={{
        position: 'relative',
        overflow: 'hidden',
        border: `1px solid ${isActive ? theme.palette.primary.main : theme.palette.divider}`,
        borderRadius: 3,
        bgcolor: isActive ? alpha(theme.palette.primary.main, 0.05) : 'background.paper',
      }}
    >
      {isActive && (
        <Box
          sx={{
            position: 'absolute',
            top: 16,
            right: -45,
            transform: 'rotate(45deg)',
            bgcolor: 'primary.main',
            width: 170,
            py: 0.5,
            textAlign: 'center',
          }}
        >
          <Typography variant="caption" sx={{ color: 'white', fontWeight: 'medium' }}>
            Current Plan
          </Typography>
        </Box>
      )}

      <Box sx={{ p: 3 }}>
        <Stack spacing={2}>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <CreditCard size={24} />
            <Typography variant="h5" fontWeight="bold">CyberSecNews.AI Premium</Typography>
          </Box>

          <Box sx={{ display: 'flex', alignItems: 'baseline', gap: 1 }}>
            <Typography variant="h3" fontWeight="bold">$6.99</Typography>
            <Typography variant="subtitle1" color="text.secondary">USD /month - Exclusive Access</Typography>
          </Box>

          {isActive && (
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <Calendar size={18} />
              <Typography variant="body2" color="text.secondary">
                Next billing date: {formatDate(subscriptionData?.current_period_end)}
              </Typography>
            </Box>
          )}

          {isActive ? renderSubscriptionStatus() : (
            <Button
              variant="contained"
              color="primary"
              startIcon={<Zap size={18} />}
              onClick={onSubscribe}
              disabled={isLoading}
              fullWidth
              sx={{ mt: 2 }}
            >
              Get Premium Access
            </Button>
          )}
        </Stack>

        <PlanFeatures />
      </Box>

      <Typography variant="body2" color="text.secondary" align="center" sx={{ mt: 2 }}>
        {isActive
          ? "Enjoy uninterrupted access to AI-powered summaries and tailored content for cybersecurity professionals."
          : "Subscribe now to save time with intelligent article summaries and get content specific to your cybersecurity needs."
        }
      </Typography>

      <SubscriptionDialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        onConfirm={() => {
          setDialogOpen(false);
          onCancel();
        }}
        title="Cancel Subscription"
        message="Are you sure you want to cancel your subscription? You'll continue to have access until the end of your billing period."
        confirmText="Yes, Cancel"
        confirmColor="error"
      />
    </Paper>
  );
};

export default PriceCard;