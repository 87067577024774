import React, { useState, useEffect, useCallback, useMemo } from 'react';
import {
  AppBar,
  Toolbar,
  TextField,
  Select,
  MenuItem,
  IconButton,
  InputAdornment,
  Tooltip,
  useTheme,
  Menu,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormLabel,
  FormControl,
  Box,
  Badge,
  Stack,
  alpha,
  Zoom,
} from '@mui/material';

import {
  Search,
  X,
  SortDesc,
  Calendar,
  User,
  RefreshCw,
} from 'lucide-react';

import { useSnackbar } from '../contexts/SnackbarContext';
import ApiService from '../services/api';
import { useAppSettings } from '../contexts/AppSettingsContext';


// Définir les valeurs par défaut des filtres
const DEFAULT_FILTERS = {
  sortOrder: 'desc',
  searchTerm: '',
  timeRange: '0',
  selectedView: 'unread',
  selectedProfile: '0',
  selectedTags: [],
  selectedImportance: 'All',
  language: 'en'
};


const PROFILES = [
  { value: '0', label: 'All Profiles' },
  { value: '1', label: 'CISO' },
  { value: '2', label: 'Cybersecurity Expert' },
  { value: '3', label: 'Incident Response' },
  { value: '4', label: 'Security Analyst' },
];


// Time range options
const TIME_RANGES = [
  { value: '1', label: 'Today' },
  { value: '2', label: 'Yesterday' },
  { value: '8', label: 'Last 7 days' },
  { value: '31', label: 'Last 30 days' },
  { value: '0', label: 'All Time' },
];

// Mark as read options
const MARK_AS_READ_OPTIONS = [
  { value: 1, label: 'Older than 1 day' },
  { value: 7, label: 'Older than 7 days' },
  { value: 14, label: 'Older than 14 days' },
  { value: 30, label: 'Older than 30 days' },
  { value: 'all', label: 'All Articles' },
];

// Component pour le badge des filtres actifs
const FilterBadge = ({ active, children, color = "primary" }) => (
  <Badge
    variant="dot"
    color={color}
    invisible={!active}
  >
    {children}
  </Badge>
);


const NewsfeedToolbar = React.memo(({
  filters = DEFAULT_FILTERS,
  defaultFilters = DEFAULT_FILTERS,
  onFilterChange = () => {},
  onResetFilters = () => {},
    savedCount = 0,
  onMarkAsRead = () => {}
}) => {
  const theme = useTheme();
  const api = ApiService();
  const { showSnackbar } = useSnackbar();

  const {
    savedArticlesCount,
    setSavedArticlesCount,
    unreadArticlesCount,
    setUnreadArticlesCount,
 } = useAppSettings();

  // États locaux
  const [searchInput, setSearchInput] = useState(filters?.searchTerm || '');
  const [markAsReadValue, setMarkAsReadValue] = useState('');

  // États des menus
  const [sortAnchorEl, setSortAnchorEl] = useState(null);
  const [profileAnchorEl, setProfileAnchorEl] = useState(null);
  const [timeAnchorEl, setTimeAnchorEl] = useState(null);

  // Mémoisation des tooltips
  const tooltips = useMemo(() => ({
    timeFilter: filters?.timeRange && filters.timeRange !== 'all'
      ? `Time Range: ${TIME_RANGES.find(r => r.value === filters.timeRange)?.label}`
      : 'Time filter',
    sort: `Sort: ${filters?.sortOrder === 'desc' ? 'Newest first' : 'Oldest first'}`,
    profile: filters?.selectedProfile
      ? `Profile: ${PROFILES.find(p => p.value === filters.selectedProfile)?.label}`
      : 'Change Profile'
  }), [filters?.timeRange, filters?.sortOrder, filters?.selectedProfile]);


  // Handler pour la recherche
  const handleSearchChange = useCallback((event) => {
    const value = event.target.value;
    setSearchInput(value);

    // Si le champ devient vide, mettre à jour les filtres
    if (value === '' && filters.searchTerm !== '') {
      onFilterChange?.({ searchTerm: '' });
    }
  }, [filters.searchTerm, onFilterChange]);

  // Handler pour effacer la recherche
  const handleSearchClear = useCallback(() => {
    // Vérifier si le champ n'est pas déjà vide
    if (searchInput !== '') {
      setSearchInput('');
      onFilterChange?.({ searchTerm: '' });
    }
  }, [searchInput, onFilterChange]);


  // Handler pour la soumission de recherche
  const handleSearchSubmit = useCallback(() => {
    // Vérifier si la recherche a changé
    if (searchInput !== filters.searchTerm) {
      onFilterChange?.({ searchTerm: searchInput });
    }
  }, [searchInput, filters.searchTerm, onFilterChange]);

  // Handler pour le changement de vue
  const handleViewChange = useCallback((event) => {
    const newView = event.target.value;
    onFilterChange?.({ selectedView: newView });
    showSnackbar(`View changed to ${newView} articles`, 'success');
  }, [onFilterChange, showSnackbar]);

  // Handlers pour le tri
  const handleSortChange = useCallback((event) => {
    onFilterChange?.({ sortOrder: event.target.value });
    setSortAnchorEl(null);
  }, [onFilterChange]);

  // Handlers pour le profil
  const handleProfileChange = useCallback((profileValue) => {
    const profile = PROFILES.find(p => p.value === profileValue);
    onFilterChange?.({ selectedProfile: profile.value });
    showSnackbar(`Profile changed to ${profile.label}`, 'success');
    setProfileAnchorEl(null);
  }, [onFilterChange, showSnackbar]);

  // Handler pour le filtre temporel
  const handleTimeFilterChange = useCallback((value) => {
    onFilterChange?.({ timeRange: value });
    setTimeAnchorEl(null);
  }, [onFilterChange]);

  // Handler pour marquer comme lu
  const handleMarkAsReadChange = useCallback(async (event) => {
    const value = event.target.value;
    setMarkAsReadValue(value);

    showSnackbar('Marking articles as read...', 'info');

    try {
      await onMarkAsRead(value);
      showSnackbar(
        `Articles ${value === 'all' ? 'all' : `older than ${value} days`} marked as read`,
        'success'
      );
    } catch (error) {
      console.error('Error marking articles as read:', error);
      showSnackbar('Error marking articles as read', 'error');
    }

    setMarkAsReadValue('');
  }, [onMarkAsRead, showSnackbar]);

  // Synchroniser la recherche avec les filtres externes
  useEffect(() => {
    setSearchInput(filters.searchTerm || '');
  }, [filters.searchTerm]);

  // Vérifier si des filtres sont actifs
  const hasActiveFilters = useMemo(() => {
    return (
      filters.searchTerm !== defaultFilters.searchTerm ||
      filters.timeRange !== defaultFilters.timeRange ||
      filters.sortOrder !== defaultFilters.sortOrder ||
      filters.selectedProfile !== defaultFilters.selectedProfile ||
      filters.selectedView !== defaultFilters.selectedView
    );
  }, [filters, defaultFilters]);

  // Handler pour réinitialiser tous les filtres
  const handleResetFilters = useCallback(() => {
    setSearchInput('');
    onFilterChange?.(defaultFilters);
    showSnackbar('All filters have been reset', 'success');
  }, [onFilterChange, defaultFilters, showSnackbar]);


  return (
    <AppBar
      position="sticky"
      color="default"
      elevation={0}
      sx={{
        backgroundColor: alpha(theme.palette.background.paper, 0.95),
        backdropFilter: 'blur(8px)',
        borderBottom: `1px solid ${theme.palette.divider}`,
      }}
    //className="bg-background/95 backdrop-blur supports-[backdrop-filter]:bg-background/60 border-b"
    >
      <Toolbar sx={{
        gap: 2,
        px: { xs: 1, sm: 2 }, // Réduire le padding sur mobile
        flexWrap: 'wrap',
      }}>
        {/* View Selection */}
        <Select
          value={filters.selectedView}
          onChange={handleViewChange}
          size="small"
          sx={{ 
            minWidth: { xs: '100%', sm: 120 }, // Pleine largeur sur mobile
            mb: { xs: 1, sm: 0 } // Marge en bas sur mobile
        }}
        >
          <MenuItem value="unread">Unread ({unreadArticlesCount})</MenuItem>
          <MenuItem value="all">All Articles</MenuItem>
          <MenuItem value="saved">Saved ({savedArticlesCount})</MenuItem>
        </Select>

        {/* Search Field */}
        <TextField
          placeholder="Search in articles"
          value={searchInput}
          onChange={handleSearchChange}
          onKeyDown={(e) => e.key === 'Enter' && handleSearchSubmit()}
          size="small"
                  sx={{ 
            flexGrow: 1,
            minWidth: { xs: '100%', sm: 'auto' }, // Pleine largeur sur mobile
            mb: { xs: 1, sm: 0 } // Marge en bas sur mobile
        }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search className="h-4 w-4" />
              </InputAdornment>
            ),
            endAdornment: searchInput && (
              <InputAdornment position="end">
                <IconButton edge="end" onClick={handleSearchClear} size="small">
                  <X className="h-4 w-4" />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />

        {/* Mark as Read Selection */}
        <Select
          value={markAsReadValue}
          onChange={handleMarkAsReadChange}
          displayEmpty
          size="small"
          sx={{ minWidth: 120 }}
        >
          <MenuItem value="" disabled>Mark as read</MenuItem>
          {MARK_AS_READ_OPTIONS.map(option => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </Select>

        {/* Action Buttons */}
        <Stack direction="row" spacing={1}>
          {/* Time Filter */}
          <Tooltip title={tooltips.timeFilter}>
            <IconButton onClick={(e) => setTimeAnchorEl(e.currentTarget)}>
              <FilterBadge active={filters.timeRange !== '0'}>
                <Calendar className="h-5 w-5" />
              </FilterBadge>
            </IconButton>
          </Tooltip>

          {/* Sort Button */}
          <Tooltip title={tooltips.sort}>
            <IconButton onClick={(e) => setSortAnchorEl(e.currentTarget)}>
              <SortDesc className="h-5 w-5" />
            </IconButton>
          </Tooltip>

          {/* Profile Button */}
          <Tooltip title={tooltips.profile}>
            <IconButton onClick={(e) => setProfileAnchorEl(e.currentTarget)}>
              <FilterBadge active={filters.selectedProfile && filters.selectedProfile > 0}>
                <User className="h-5 w-5" />
              </FilterBadge>
            </IconButton>
          </Tooltip>
        </Stack>

        {/* Bouton de réinitialisation */}
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Zoom in={hasActiveFilters}>
            <Tooltip title="Reset all filters">
              <IconButton
                onClick={handleResetFilters}
                sx={{
                  transition: theme.transitions.create(['transform', 'background-color']),
                  '&:hover': {
                    transform: 'rotate(-180deg)',
                    backgroundColor: alpha(theme.palette.primary.main, 0.1),
                  },
                }}
              >
                <RefreshCw
                  className="h-5 w-5"
                  style={{ color: theme.palette.primary.main }}
                />
              </IconButton>
            </Tooltip>
          </Zoom>
        </Box>

        {/* Menus */}
        {/* Time Filter Menu */}
        <Menu
          anchorEl={timeAnchorEl}
          open={Boolean(timeAnchorEl)}
          onClose={() => setTimeAnchorEl(null)}
        >
          <Box sx={{ p: 2, minWidth: 200 }}>
            <FormControl>
              <FormLabel>Time Range</FormLabel>
              <RadioGroup
                value={filters.timeRange || '0'}
                onChange={(e) => handleTimeFilterChange(e.target.value)}
              >
                {TIME_RANGES.map(range => (
                  <FormControlLabel
                    key={range.value}
                    value={range.value}
                    control={<Radio />}
                    label={range.label}
                  />
                ))}
              </RadioGroup>
            </FormControl>
          </Box>
        </Menu>

        {/* Sort Menu */}
        <Menu
          anchorEl={sortAnchorEl}
          open={Boolean(sortAnchorEl)}
          onClose={() => setSortAnchorEl(null)}
        >
          <Box sx={{ p: 2, minWidth: 200 }}>
            <FormControl>
              <FormLabel>Sort Order</FormLabel>
              <RadioGroup
                value={filters.sortOrder}
                onChange={handleSortChange}
              >
                <FormControlLabel value="desc" control={<Radio />} label="Newest first" />
                <FormControlLabel value="asc" control={<Radio />} label="Oldest first" />
              </RadioGroup>
            </FormControl>
          </Box>
        </Menu>

        {/* Profile Menu */}
        <Menu
          anchorEl={profileAnchorEl}
          open={Boolean(profileAnchorEl)}
          onClose={() => setProfileAnchorEl(null)}
        >
          {PROFILES.map(profile => (
            <MenuItem
              key={profile.value}
              onClick={() => handleProfileChange(profile.value)}
              selected={profile.value === filters.selectedProfile}
            >
              {profile.label}
            </MenuItem>
          ))}
        </Menu>
      </Toolbar>
    </AppBar>
  );
});

NewsfeedToolbar.displayName = 'NewsfeedToolbar';

export default NewsfeedToolbar;