import React, { useState, useEffect, useContext } from 'react';
import { PieChart, Pie, Cell, ResponsiveContainer, Tooltip } from 'recharts';
import { Card, CardContent, Typography, List, ListItem, ListItemText, Box, Skeleton } from '@mui/material';
import { useAppSettings } from '../../contexts/AppSettingsContext';
import ApiService from '../../services/api';
//import { getItemWithExpiry } from '../../UserContext';
import { useTheme } from '@mui/material/styles';
import ErrorMessage from '../ErrorMessage';

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#8884D8', '#82CA9D', '#A4DE6C'];

const transformData = (apiData) => {
  return Object.entries(apiData).map(([name, value]) => ({ name, value }));
};

const ArticlesbyDomain = ({ period }) => {
  const [domainData, setDomainData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const lastlogin = localStorage.getItem('lastLogin');
  const theme = useTheme();
  const critical_color = theme.palette.priority.critical;
  const high_color = theme.palette.priority.high;
  const medium_color = theme.palette.priority.medium;
  const low_color = theme.palette.priority.low;

  const api = ApiService();
  const {
    selectedPeriod,
    setSelectedPeriod,
  }  = useAppSettings();  

  const fetchData = async () => {
    try {
      setIsLoading(true);
      let params = {};

      if (isNaN(selectedPeriod)) {
        // Si period n'est pas un nombre, on suppose que c'est une date ISO
        params.last_login = lastlogin;
      } else {
        // Sinon, c'est un nombre de jours
        params.days = selectedPeriod;
      }
      const response = await api.get('/stats/articles_count_by_domain', params);
      const transformedData = transformData(response.data);
      setDomainData(transformedData);
      setIsLoading(false);
    } catch (err) {
      setError('Une erreur est survenue lors du chargement des données.');
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [selectedPeriod]);

  if (isLoading) {
    return (
      <Card sx={{ minWidth: 400, maxWidth: 600, boxShadow: 3 }}>
        <CardContent>
          <Skeleton variant="text" width="60%" />
          <Skeleton variant="rectangular" height={400} />
          <Skeleton variant="text" width="80%" />
        </CardContent>
      </Card>
    );
  }
  if (error) {
    return <ErrorMessage message={error.message} />;
  }

  const totalArticles = domainData.reduce((sum, item) => sum + item.value, 0);

  return (
    <Card sx={{ width: '100%', height: '100%', minHeight: 500, boxShadow: 3  }}> {/* Augmenter la hauteur minimale */}
    <CardContent>
    <Typography variant="h5" component="div" color="text.secondary" gutterBottom>
        ARTICLES BY DOMAIN
      </Typography>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <ResponsiveContainer width="50%" height={300}>
          <PieChart>
            <Pie
              data={domainData}
              cx="50%"
              cy="65%"
              labelLine={false}
              outerRadius={100}
              innerRadius={40}
              fill="#8884d8"
              dataKey="value"
            >
              {domainData.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
              ))}
            </Pie>
            <Tooltip />
          </PieChart>
        </ResponsiveContainer>
        <List sx={{ width: '50%', maxHeight: 300, overflow: 'none'}}>
          {domainData.map((item, index) => (
            <ListItem key={item.name}>
              <ListItemText
                primary={item.name}
                secondary={`${item.value} articles (${((item.value / totalArticles) * 100).toFixed(1)}%)`}
              />
              <Box
                width={16}
                height={16}
                bgcolor={COLORS[index % COLORS.length]}
                borderRadius="50%"
                ml={2}
              />
            </ListItem>
          ))}
        </List>
      </Box>
      </CardContent>
    </Card>
  );
};

export default ArticlesbyDomain;