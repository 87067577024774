import React from 'react';
import { Card, CardContent, Typography, Button } from '@mui/material';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

const ErrorMessage = ({ message, onRetry }) => {
  return (
    <Card sx={{ 
      minWidth: 400, 
      maxWidth: 600, 
      boxShadow: 3, 
      textAlign: 'center', 
      py: 4,
      backgroundColor: (theme) => theme.palette.error.light
    }}>
      <CardContent>
        <ErrorOutlineIcon sx={{ fontSize: 60, color: 'error.main', mb: 2 }} />
        <Typography variant="h5" component="div" color="error.main" gutterBottom>
          Oops! Something went wrong
        </Typography>
        <Typography variant="body1" color="text.secondary" sx={{ mb: 3 }}>
          {message || "We're having trouble loading this content. Please try again."}
        </Typography>
        {onRetry && (
          <Button 
            variant="contained" 
            color="primary" 
            onClick={onRetry}
            sx={{ mt: 2 }}
          >
            Try Again
          </Button>
        )}
      </CardContent>
    </Card>
  );
};

export default ErrorMessage;