import React, { useState, useEffect, useContext } from 'react';
import { Box, Card, CardContent, Skeleton } from '@mui/material';
import { useAppSettings } from '../../contexts/AppSettingsContext';
import { ErrorOutline } from '@mui/icons-material';
import MetricCard from './MetricCard';
import ApiService from '../../services/api';
import { Article } from '@mui/icons-material';
import ErrorMessage from '../ErrorMessage';

const TotalArticlesCard = () => {
  const [totalData, setTotalData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const api = ApiService();
  const {selectedPeriod,setSelectedPeriod}  = useAppSettings(); 
  const lastLogin = localStorage.getItem('lastLogin');

  useEffect(() => {
    const fetchTotalData = async () => {
      try {
        setLoading(true);
        const params = isNaN(selectedPeriod) 
          ? { last_login: lastLogin } 
          : { days: selectedPeriod };
        const response = await api.get('/stats/total_articles_trend', params );
        setTotalData(response.data);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchTotalData();
  }, [selectedPeriod, lastLogin]);

  if (loading) {
    return (
      <Card sx={{ minWidth: 400, maxWidth: 600, boxShadow: 3 }}>
        <CardContent>
          <Skeleton variant="text" width="60%" />
          <Skeleton variant="rectangular" height={150} />
          <Skeleton variant="text" width="80%" />
        </CardContent>
      </Card>
    );
  }
  if (error) {
    return <ErrorMessage message={error.message} />;
  }
  if (!totalData) return null;

  // Supposons que criticalData contient les champs suivants :
  // count: nombre total d'articles critiques
  // change: pourcentage de changement
  // current_month: nombre d'articles ce mois-ci
  // previous_month: nombre d'articles le même jour le mois dernier

  return (
    <MetricCard
      title="TOTAL ARTICLES"
      value={totalData.count}
      change={totalData.change}
      comparisonText={isNaN(selectedPeriod) ? "Since last login" : `Last ${selectedPeriod} days`}
      icon={Article}
      iconColor="info"
      currentMonthArticles={totalData.current_month}
      previousMonthArticles={totalData.previous_month}
    />
  );
};

export default TotalArticlesCard;