import React from 'react';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from '@mui/material';

const ExitConfirmationDialog = ({ open, onClose, onConfirm }) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="exit-dialog-title"
      aria-describedby="exit-dialog-description"
    >
      <DialogTitle id="exit-dialog-title">
        Quit
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="exit-dialog-description">
            Logout From CyberSecNews?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          No
        </Button>
        <Button onClick={onConfirm} color="primary" autoFocus>
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ExitConfirmationDialog;