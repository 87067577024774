import React, { useState } from 'react';
import {
    Box,
    Button,
    Typography,
    Alert,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    TextField,
    Paper,
    Stack,
    useTheme,
    alpha
} from '@mui/material';
import {
    AlertTriangle,
    Trash2,
    Lock,
    UserMinus,
    AlertCircle
} from 'lucide-react';
import ApiService from '../../services/api';
import { useKeycloak } from '@react-keycloak/web';

const DangerAction = ({ icon: Icon, title, description, buttonText, onClick }) => {
    const theme = useTheme();
    return (
        <Paper
            elevation={0}
            sx={{
                p: 3,
                border: `1px solid ${theme.palette.error.light}`,
                borderRadius: 2,
                bgcolor: alpha(theme.palette.error.main, 0.05),
            }}
        >
            <Stack direction="row" spacing={2} alignItems="flex-start">
                <Icon color={theme.palette.error.main} size={24} />
                <Box sx={{ flex: 1 }}>
                    <Typography variant="h6" gutterBottom color="error">
                        {title}
                    </Typography>
                    <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
                        {description}
                    </Typography>
                    <Button
                        variant="outlined"
                        color="error"
                        onClick={onClick}
                        startIcon={<AlertTriangle size={16} />}
                        sx={{ borderRadius: 2 }}
                    >
                        {buttonText}
                    </Button>
                </Box>
            </Stack>
        </Paper>
    );
};

const DeleteAccountConfirmationDialog = ({ open, onClose, onConfirm }) => {
    const [confirmText, setConfirmText] = useState('');
    const [error, setError] = useState('');

    const handleConfirm = () => {
        if (confirmText.toLowerCase() !== 'delete my account') {
            setError('Please type the confirmation text exactly as shown');
            return;
        }
        onConfirm();
    };

    return (
        <Dialog 
            open={open} 
            onClose={onClose}
            maxWidth="sm"
            fullWidth
        >
            <DialogTitle sx={{ pb: 1 }}>
                <Stack direction="row" spacing={1} alignItems="center">
                    <UserMinus color="error" />
                    <Typography variant="h6" color="error">
                        Delete Account Permanently
                    </Typography>
                </Stack>
            </DialogTitle>
            <DialogContent>
                <Alert severity="warning" sx={{ mb: 3 }}>
                    <Typography variant="body2">
                        This action is irreversible. All your data will be permanently deleted.
                    </Typography>
                </Alert>
                <Stack spacing={3}>
                    <Box>
                        <Typography variant="body2" gutterBottom>
                            Please type <strong>delete my account</strong> to confirm:
                        </Typography>
                        <TextField
                            fullWidth
                            value={confirmText}
                            onChange={(e) => {
                                setConfirmText(e.target.value);
                                setError('');
                            }}
                            error={!!error}
                            helperText={error}
                            size="small"
                            sx={{ mt: 1 }}
                        />
                    </Box>
                    <Typography variant="body2" color="text.secondary">
                        By confirming, you acknowledge that:
                        <ul>
                            <li>Your account will be permanently deleted</li>
                            <li>All your saved articles and preferences will be removed</li>
                            <li>Your subscription will be cancelled if active</li>
                            <li>This action cannot be undone</li>
                        </ul>
                    </Typography>
                </Stack>
            </DialogContent>
            <DialogActions sx={{ p: 2, pt: 0 }}>
                <Button onClick={onClose} sx={{ borderRadius: 2 }}>
                    Cancel
                </Button>
                <Button
                    variant="contained"
                    color="error"
                    onClick={handleConfirm}
                    startIcon={<Trash2 size={16} />}
                    sx={{ borderRadius: 2 }}
                >
                    Delete My Account
                </Button>
            </DialogActions>
        </Dialog>
    );
};

const AccountSection = () => {
    const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);
    const { keycloak } = useKeycloak();
    const api = ApiService();


    
    const handleDeleteAccount = async () => {
        try {
            setIsDeleting(true);
            
            // Appel à l'API pour supprimer le compte
            await api.delete('/users/account');
            
            // Déconnexion de Keycloak
            await keycloak.logout({ redirectUri: process.env.REACT_APP_KEYCLOAK_LOGOUT_URL });
        } catch (error) {
            console.error('Error deleting account:', error);
            // Gérer l'erreur (vous pourriez ajouter un état pour afficher un message d'erreur)
        } finally {
            setIsDeleting(false);
            setIsDeleteDialogOpen(false);
        }
    };

    return (
        <Paper
            elevation={0}
            sx={{
                border: (theme) => `1px solid ${theme.palette.divider}`,
                borderRadius: 3,
                overflow: 'hidden'
            }}
        >
            <Box
                sx={{
                    p: 3,
                    borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
                    display: 'flex',
                    alignItems: 'center',
                    gap: 1
                }}
            >
                <Lock size={20} />
                <Typography variant="h6" fontWeight="bold">
                    Account
                </Typography>
            </Box>

            <Box sx={{ p: 3 }}>
                <Stack spacing={4}>
{/*                     <Box>
                        <Typography variant="subtitle1" gutterBottom fontWeight="medium">
                            Account Security
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                            Manage your account security settings and authentication preferences.
                        </Typography>
                        <Button
                            variant="outlined"
                            sx={{ mt: 2, borderRadius: 2 }}
                            onClick={() => window.open(process.env.REACT_APP_KEYCLOAK_ACCOUNT_URL, '_blank')}
                        >
                            Manage Security Settings
                        </Button>
                    </Box> */}

                    <Box>
                        <Typography variant="subtitle1" gutterBottom fontWeight="medium" color="error">
                            Danger Zone
                        </Typography>
                        <DangerAction
                            icon={AlertCircle}
                            title="Delete Account"
                            description="Permanently delete your account and all associated data. This action cannot be undone."
                            buttonText="Delete Account"
                            onClick={() => setIsDeleteDialogOpen(true)}
                        />
                    </Box>
                </Stack>
            </Box>

            <DeleteAccountConfirmationDialog
                open={isDeleteDialogOpen}
                onClose={() => setIsDeleteDialogOpen(false)}
                onConfirm={handleDeleteAccount}
            />
        </Paper>
    );
};

export default AccountSection;