import React, { useState, useEffect } from 'react';
import { Box, IconButton, Fade, useTheme } from '@mui/material';
import { ChevronLeft, ChevronRight, ArrowUp } from 'lucide-react';
import { useTheme as useCustomTheme } from '../contexts/ThemeContext';

const FloatingNav = () => {
  const [showScroll, setShowScroll] = useState(false);
  const theme = useTheme();
  const { isDarkMode, toggleTheme } = useCustomTheme();

  // Gérer l'affichage du bouton de défilement
  useEffect(() => {
    const checkScrollTop = () => {
      if (!showScroll && window.scrollY > 400) {
        setShowScroll(true);
      } else if (showScroll && window.scrollY <= 400) {
        setShowScroll(false);
      }
    };

    window.addEventListener('scroll', checkScrollTop);
    return () => window.removeEventListener('scroll', checkScrollTop);
  }, [showScroll]);

  // Fonction pour remonter en haut
  const scrollTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <Fade in={showScroll}>
      <Box
        sx={{
          position: 'fixed',
          bottom: 24,
          right: 24,
          zIndex: theme.zIndex.fab,
          display: 'flex',
          alignItems: 'center',
          gap: 1,
          padding: '6px',
          bgcolor: 'background.paper',
          border: '1px solid',
          borderColor: 'divider',
          borderRadius: '10px',
          boxShadow: theme.shadows[4],
          transition: 'transform 0.2s ease-in-out',
          '&:hover': {
            transform: 'scale(1.02)',
          },
        }}
      >
{/*         <IconButton 
          size="small"
          onClick={() => window.history.back()}
          sx={{ color: 'text.secondary' }}
        >
          <ChevronLeft size={20} />
        </IconButton> */}

        <IconButton
          size="small"
          onClick={scrollTop}
          sx={{ color: 'text.secondary' }}
        >
          <ArrowUp size={20} />
        </IconButton>

{/*         <IconButton
          size="small"
          onClick={() => window.history.forward()}
          sx={{ color: 'text.secondary' }}
        >
          <ChevronRight size={20} />
        </IconButton> */}

        <Box
          component="span"
          sx={{
            height: '24px',
            mx: 0.5,
            borderLeft: '1px solid',
            borderColor: 'divider',
          }}
        />

        <Box
          onClick={toggleTheme}
          sx={{
            width: 40,
            height: 24,
            borderRadius: 12,
            bgcolor: 'background.default',
            border: '1px solid',
            borderColor: 'divider',
            position: 'relative',
            cursor: 'pointer',
            transition: 'all 0.2s ease-in-out',
          }}
        >
          <Box
            sx={{
              position: 'absolute',
              top: 2,
              left: isDarkMode ? 'calc(100% - 18px)' : 2,
              width: 18,
              height: 18,
              borderRadius: '50%',
              bgcolor: isDarkMode ? 'text.primary' : 'primary.main',
              transition: 'left 0.2s ease-in-out',
            }}
          />
        </Box>
      </Box>
    </Fade>
  );
};

export default FloatingNav;